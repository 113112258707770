import React, { useState } from "react";
import { CreateJobFormOne } from "../../../components/organism/Forms/CreateJobFormOne";
import { CreateJobFormTwo } from "../../../components/organism/Forms/CreateJobFormTwo";
import { TabComponent } from "../../../components/molecule/Tabs/TabComponent";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";


const CreateJobScreen = ({ getskillList }) => {
    const [activeTab, setActiveTab] = useState('Job Details');

    const tabList = ['Job Details', 'Job Description'];

    const tabContent = {
        'Job Details': <CreateJobFormOne setActiveTab={setActiveTab} />,  
        'Job Description': <CreateJobFormTwo setActiveTab={setActiveTab}/>,
    };

    return (
        <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
            <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                <div className="bg-white p-4">
                    <p className="text-2xl text-darkBlue font-semibold font-DM">Create your job post</p>
                    <TabComponent 
                        tabList={tabList} 
                        tabContent={tabContent} 
                        activeTab={activeTab} 
                        setActiveTab={setActiveTab} 
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(
    state => ({
      resetPasswordState: state.auth.get("resetPasswordState"),
    }),
    {
        getskillList: Actions.common.getskillList,
  
  
    },
  )(CreateJobScreen);
