import { ListItem, ListItemPrefix } from "@material-tailwind/react";
import React, { useContext, useEffect, useState } from "react";
// import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  AddIcon
} from "../../../assets/svg";
import { USER_ROLE } from "../../../core/Constant";
import { Context } from "../../../core/Context/Context";
import { navigateTo } from "../../../core/services/NavigationServicd";
import { getUserType } from "../../../core/UtilMethods";
import { SUBMENUS } from "../../../Menus/SubMenu";

export const SidebarContainer = () => {
  const [isExpand, setIsExpand] = useState(true);
  const [candidateShortMenu, setCandidateShortMenu] = useState(false);
  const [candidateLongMenu, setCandidateLongMenu] = useState(false);

  const handleClickPrimaryOption = (clickOption, list = []) => {
    if (clickOption == "Candidate" && list.length > 0) {
      setIsExpand(true);
    } else {
      setIsExpand(false);
    }
  };

  useEffect(() => {
    const type = getUserType();
    const pathname = window.location.pathname;
    console.log("pathname");
    if (
      (type == USER_ROLE.candidate && pathname == "/dashboard") ||
      pathname == "/messages" ||
      pathname == "/interviews" ||
      pathname == "/my-jobs"
    ) {
      setCandidateShortMenu(true);
      setCandidateLongMenu(false)
    } else if (
      (type == USER_ROLE.candidate && pathname == "/profile") ||
      pathname == "/templates" ||
      pathname == "/cv" ||
      pathname == "/settings" ||
      pathname == "/skill-requrement"
    ) {
      setCandidateShortMenu(false);
      setCandidateLongMenu(true)
    }
    console.log(window.location.pathname);
  }, []);


  const oncreateJob = () =>{
   navigateTo('/recruiter_createjob')
  }

  return (
    <div className="sidebar-primary-container flex flex-col gap-y-2">
      {getUserType() == USER_ROLE.recruiter ? (
        <button onClick={oncreateJob}  className=" p-2 rounded-md bg-primaryColor w-full">
          <div className="flex items-start justify-center">
            <p className="font-DM text-white font-semibold ">Post a job</p>
            <AddIcon />
          </div>
        </button>
      ) : null}

      <div className="sidebar-container">
        {candidateShortMenu ? (
          <>
            {SUBMENUS.DashboardSubMenu?.map((item, index) => {
              return (
                <Link to={item?.url} key={index + 1}>
                  <ListItem className="hover:bg-bgColor active:bg-bgColor">
                    <ListItemPrefix>{item?.icon}</ListItemPrefix>
                    <span className="sidebar-text">{item?.name}</span>
                  </ListItem>
                </Link>
              );
            })}
          </>
        ) : null}

        {!candidateShortMenu && candidateLongMenu ? (
          <>
            {SUBMENUS.ProfileSubMenu?.map((item, index) => {
              return (
                <Link to={item?.url} key={index + 1}>
                  <ListItem className="hover:bg-bgColor active:bg-bgColor">
                    <ListItemPrefix>{item?.icon}</ListItemPrefix>
                    <span className="sidebar-text">{item?.name}</span>
                  </ListItem>
                </Link>
              );
            })}
          </>
        ) : null}

        {getUserType() == USER_ROLE.recruiter ? (
          <>
            {SUBMENUS.RecruitSubMenuDashboard?.map((item, index) => {
              return (
                <Link
                  to={item?.url}
                  key={index + 1}
                  onClick={() =>
                    handleClickPrimaryOption(item?.name, item?.nest_menu)
                  }
                >
                  <ListItem className="hover:bg-bgColor active:bg-bgColor grid grid-cols-5 items-center">
                    <div className="col-span-1">
                      <ListItemPrefix>{item?.icon}</ListItemPrefix>
                    </div>

                    <span className="sidebar-text col-span-3">
                      {item?.name}
                    </span>
                    {item?.nest_menu?.length > 0 ? (
                      <div className="col-span-1">
                        <svg
                          width="9"
                          height="5"
                          viewBox="0 0 9 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="rotate-180"
                        >
                          <path
                            d="M8.14463 4.32739C8.01076 4.46046 7.82967 4.53516 7.64092 4.53516C7.45216 4.53516 7.27108 4.46046 7.13721 4.32739L4.57223 1.79814L2.04298 4.32739C1.90911 4.46046 1.72802 4.53516 1.53927 4.53516C1.35051 4.53516 1.16943 4.46046 1.03556 4.32739C0.968593 4.26097 0.91544 4.18195 0.879167 4.09488C0.842894 4.00782 0.824219 3.91443 0.824219 3.82011C0.824219 3.72579 0.842894 3.6324 0.879167 3.54534C0.91544 3.45827 0.968593 3.37925 1.03556 3.31283L4.06495 0.28344C4.13137 0.216473 4.21039 0.16332 4.29746 0.127046C4.38453 0.0907733 4.47791 0.0720979 4.57223 0.0720979C4.66655 0.0720979 4.75994 0.0907733 4.847 0.127046C4.93407 0.16332 5.01309 0.216473 5.07951 0.28344L8.14463 3.31283C8.21159 3.37925 8.26475 3.45827 8.30102 3.54534C8.33729 3.6324 8.35597 3.72579 8.35597 3.82011C8.35597 3.91443 8.33729 4.00782 8.30102 4.09488C8.26475 4.18195 8.21159 4.26097 8.14463 4.32739Z"
                            fill="#2B3674"
                          />
                        </svg>
                      </div>
                    ) : null}
                  </ListItem>
                  {isExpand ? (
                    <>
                      {item?.nest_menu?.length > 0 &&
                        item?.nest_menu?.map((subItem, index) => {
                          return (
                            <Link to={subItem?.url} key={index + 1}>
                              <ListItem className="hover:bg-bgColor active:bg-bgColor grid grid-cols-5 items-center">
                                <div className="col-span-1"></div>
                                <span className="sidebar-text col-span-3">
                                  {subItem?.name}
                                </span>
                              </ListItem>
                            </Link>
                          );
                        })}
                    </>
                  ) : null}
                </Link>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};
