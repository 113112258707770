import {Map} from 'immutable';
import _ from 'lodash';
import {ModuleEvents} from './Actions';
import { AUTH_PROCESS_STEPS } from '../../core/Constant';
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: {loading: false, action: {}},
  signUpresponse:{},
  userDetails: {},
  currentUser:{},
  currentUserDetails:{},
  registerCandidate:{},
  authstep:{step:AUTH_PROCESS_STEPS.create_account},
  profile_picture:''
});

export const Reducer = (state = InitialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set('loadingAction', {loading: true, action: payload});
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set('loadingAction', {loading: false, action: payload});
    }
    case ModuleEvents.RECRUITER_SIGN_UP: {
      return state.set('signUpresponse', payload);
    }
    case ModuleEvents.STORE_USER_DETAILS: {
      return state.set('userDetails', { ...state.get('userDetails'), ...payload });
    }
   
    case ModuleEvents.LOGIN_USER: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("currentUserDetails", data);
      }
      return state;
    }

    case ModuleEvents.REGISTER_CANDIDATE: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("registerCandidate", data);
      }
      return state;
    }

    case ModuleEvents.STORE_AUTH_STEP: {
      return state.set('authstep', { ...state.get('authstep'), ...payload });
    }

    case ModuleEvents.STORE_PROFILE_PIC: {
      return state.set('profile_picture', { ...state.get('profile_picture'), ...payload });
    }
  }
  

  return state;
};
