export const SalaryGuideIcon = ({isSelected = false}) => {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_132)">
        <path d="M1.22461 9.05853V13.4824H4.29423V8.63721H1.73621C1.60053 8.63721 1.4704 8.6816 1.37445 8.76061C1.27851 8.83962 1.22461 8.94679 1.22461 9.05853Z" fill="#99A2BF"/>
        <path d="M4.80566 6.74114V13.4823H7.87529V6.31982H5.31727C5.18158 6.31982 5.05145 6.36421 4.95551 6.44323C4.85957 6.52224 4.80566 6.6294 4.80566 6.74114Z" fill="#99A2BF"/>
        <path d="M17.1577 3.25862L12.8183 0.1054C12.7248 0.0374797 12.6044 0 12.4798 0C12.3551 0 12.2348 0.0374797 12.1413 0.1054L7.80196 3.25862C7.73122 3.30885 7.67812 3.37378 7.64819 3.44664C7.61825 3.51951 7.61256 3.59764 7.63172 3.67293C7.65703 3.76647 7.7203 3.8501 7.81109 3.91004C7.90189 3.96997 8.01478 4.00263 8.13114 4.00261H8.38694V13.4823H16.5726V4.00261H16.8284C16.9448 4.00263 17.0577 3.96997 17.1484 3.91004C17.2392 3.8501 17.3025 3.76647 17.3278 3.67293C17.347 3.59766 17.3413 3.51953 17.3114 3.44666C17.2815 3.3738 17.2284 3.30887 17.1577 3.25862ZM11.9682 6.7412H12.9914C13.3984 6.7412 13.7888 6.87437 14.0766 7.1114C14.3645 7.34844 14.5262 7.66994 14.5262 8.00516C14.5262 8.34038 14.3645 8.66188 14.0766 8.89892C13.7888 9.13595 13.3984 9.26912 12.9914 9.26912V9.69044H11.9682V9.26912C11.5613 9.26875 11.1711 9.13546 10.8834 8.8985C10.5957 8.66154 10.4338 8.34027 10.4334 8.00516H11.4566C11.4567 8.11686 11.5107 8.22395 11.6066 8.30293C11.7025 8.38192 11.8325 8.42635 11.9682 8.42648H12.9914C13.1271 8.42648 13.2572 8.38209 13.3531 8.30308C13.4491 8.22407 13.503 8.1169 13.503 8.00516C13.503 7.89342 13.4491 7.78625 13.3531 7.70724C13.2572 7.62823 13.1271 7.58384 12.9914 7.58384H11.9682C11.5611 7.58384 11.1707 7.45067 10.8829 7.21363C10.5951 6.97659 10.4334 6.6551 10.4334 6.31988C10.4334 5.98465 10.5951 5.66316 10.8829 5.42612C11.1707 5.18908 11.5611 5.05592 11.9682 5.05592V4.6346H12.9914V5.05592C13.3983 5.05629 13.7884 5.18958 14.0761 5.42654C14.3639 5.66349 14.5257 5.98477 14.5262 6.31988H13.503C13.5028 6.20818 13.4489 6.10109 13.353 6.02211C13.257 5.94312 13.127 5.89869 12.9914 5.89856H11.9682C11.8325 5.89856 11.7024 5.94295 11.6064 6.02196C11.5105 6.10097 11.4566 6.20814 11.4566 6.31988C11.4566 6.43162 11.5105 6.53878 11.6064 6.6178C11.7024 6.69681 11.8325 6.7412 11.9682 6.7412Z" fill="#99A2BF"/>
        </g>
        <defs>
        <clipPath id="clip0_2_132">
        <rect width="16.3713" height="13.4823" fill="white" transform="translate(0.96875)"/>
        </clipPath>
        </defs>
        </svg>
        
        
    )
}