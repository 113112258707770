import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { ProfileCard } from "../Cards";
import _ from "lodash";

export const ProfileViewModal = ({ onClose, open = false, data = [] }) => {
  const cancelButtonRef = useRef(null);
  useEffect(() => {
    console.log("mkmkmkm", data);
  }, [data]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  bg-black w-full min-w-[813px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div class="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                        <div class="flex flex-row w-full">
                          <span class=" text-lableColor font-DM font-[500] ml-5 text-[28px]">
                            Profile Views
                          </span>
                        </div>
                        <CloseButton onClick={onClose} />
                      </div>
                      <div className="w-full flex flex-row justify-between flex-wrap">
                        {_.map(_.get(data, "views"), (item, index) => {
                          return (
                            <div key={index + 1}>
                              <ProfileCard viewData={item} />
                            </div>
                          );
                        })}
                      </div>
                      {/* <div className='flex w-full flex-row items-center justify-between p-0'>
                                                <span
                                                    class="text-ash  font-DM font-[500] ml-5 text-[16px]">
                                                    Previous
                                                </span>
                                                <hr className="h-px my-8 bg-liteGray border-0 dark:bg-gray-700 w-full ml-4"></hr>


                                            </div> */}
                      {/* <div className='w-full flex flex-row justify-between flex-wrap'>
                                                <ProfileCard />
                                                <ProfileCard />
                                                <ProfileCard />
                                                <ProfileCard />
                                                <ProfileCard />
                                                <ProfileCard />
                                            </div> */}
                      {/* <div className='w-1/3 flex items-center  text-center justify-center my-8'>
                                                <ButtonComponent text='View More' isPrimary={false} />

                                            </div> */}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
