import React, { useEffect, useState } from "react";
import { ButtonComponent, DropdownComponent, TabButtonComponent, TagComponent, TextAreaComponent, TextFieldComponent } from "../../../components/atoms";
import { ScreeningCard } from "../../../components/molecule";
import { CalenderLayout } from "../../../components";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { EventDetailsModal } from "../../../components/molecule/Modals";
import { IMAGE_URL } from "../../../core/Constant";

const InterviewRecruiterSCreen = ({ isInitial = false, getAllJobRecruiter, recruiterJobs, getInterviewList, getInterviewDetails, interviewList }) => {

  const [jobPost, setJobPost] = useState("");
  const [jobs, setJobs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [appliedCandidates, setappliedCandidates] = useState()
  const [qualifiedCandidates, setqualifiedCandidates] = useState()
  const [rejectedCandidates, setrejectedCandidates] = useState()
  const [interviewlist, setInterviewList] = useState()
  const [event, setEvents] = useState()
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);


  useEffect(() => {
    getAllJobRecruiter()
  }
    , [])


  useEffect(() => {
    setJobs(recruiterJobs || []);
  }, [recruiterJobs]);

  const handleJobPostChange = (e) => {
    setJobPost(e.target.value);
    setShowDropdown(true);
  };

  const handleJobPostSelect = (job) => {
    setJobPost(job.title);
    setSelectedJob(job);
    setShowDropdown(false);

  };

  useEffect(() => {
    if(selectedJob?.id){
      getInterviewList(selectedJob?.id)
    }
  }, [selectedJob])

  useEffect(() => {
    setInterviewList(interviewList)
    console.log(interviewList, 'interview Listttt')
    if (interviewList) {
      const events = interviewList.map((interview) => ({
        event_date: new Date(interview.date + "T" + interview.time_from),
        event_title: interview.title,
        event_time: interview.time_from.slice(0, 5),
        data:interview
      }));
      setEvents(events)
      console.log(events, 'eventssssssss')
    }

  }, [interviewList])

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };



  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        <div className="bg-white p-4 rounded-xl mb-2">
          <p className="text-2xl text-darkBlue font-bold font-DM">Interview  Calendar</p>
          <div className="relative">
            <TextFieldComponent
              placeholder="Select Job Post"
              value={jobPost}
              onChange={handleJobPostChange}
              label="Job Post"
            />
            {showDropdown && jobPost && (
              <div className="absolute z-10 bg-white shadow-md max-h-40 overflow-y-auto w-full">
                {jobs
                  .filter((job) => job.title.toLowerCase().includes(jobPost.toLowerCase()))
                  .map((job, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-blue-200"
                      onClick={() => handleJobPostSelect(job)}
                    >
                      {job.title}
                    </div>
                  ))}
              </div>
            )}
          </div>

  
          {selectedJob && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-100 flex items-start">
              <img
                src={IMAGE_URL + selectedJob.job_image}
                alt={`${selectedJob?.title} image`}
                className="w-20 h-20 rounded-full object-cover mr-4"
              />
              <div>
                <p>
                  <strong>Job Title:</strong> {selectedJob.title}
                </p>
                <p>
                  <strong>Job Description:</strong> {selectedJob.description}
                </p>
                <p>
                  <strong>Location:</strong> {selectedJob.location}
                </p>
              </div>
            </div>
          )}
        </div>
        <CalenderLayout newevents={event} onEventClick={handleEventClick} />

        {selectedEvent && (
          <EventDetailsModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            eventDetails={selectedEvent.data}
          />
        )}

      </div>
    </div>
  );
};


export default connect(
  (state) => ({
    recruiterJobs: state.jobs.get("recruiterJobs"),
    jobPost: state.jobs.get("jobPost"),
    questionList: state.home.get('questionList'),
    appliedList: state.home.get('appliedList'),
    interviewList: state.home.get('interviewList')
  }),
  {
    getAllJobRecruiter: Actions.jobs.getAllJobRecruiter,
    getInterviewList: Actions.home.getInterviewList,
    getInterviewDetails: Actions.home.getInterviewDetails


  }
)(InterviewRecruiterSCreen);
