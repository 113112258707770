import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from '@headlessui/react';

export const DropdownComponent = ({
  label,
  defaultOption = "Select an option",
  isDisabled,
  error,
  errortxt,
  value, // Value passed from parent
  dropdownOptions = [],
  onOptionSelected, // Prop to handle selection
}) => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown open/close state
  const [selectedOption, setSelectedOption] = useState(defaultOption); // Default selected option

  useEffect(() => {
    // If a value is passed from the parent, set it as the selected option
    if (value) {
      const initialOption = dropdownOptions.find((option) => option.name === value.name);
      if (initialOption) {
        setSelectedOption(initialOption.name); // Set selected option if it exists in dropdown options
      } else {
        setSelectedOption(defaultOption); // Fallback to default option if value not found
      }
    }
  }, [value, dropdownOptions, defaultOption]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle dropdown open/close state
  };

  const handleOptionSelect = (option) => {
    setIsOpen(false); // Close dropdown after selection
    setSelectedOption(option.name); // Set the clicked option as the selected option
    if (onOptionSelected) {
      onOptionSelected(option); // Pass the selected option to the parent
    }
  };

  return (
    <div className="input-field-wrap items-start w-full">
      <label className="input-label-txt">{label}</label>
      <Menu as="div" className="relative">
        <Menu.Button
          onClick={toggleDropdown}
          disabled={isDisabled}
          className={
            isDisabled
              ? "input-field-disabled"
              : error
              ? "error-input-field"
              : "input-field-basic"
          }
        >
          <div className="flex justify-between opacity-55 font-normal">
            <p>{selectedOption || defaultOption}</p> {/* Display selected option or default */}
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="dropdown-option-wrap">
            {dropdownOptions.length > 0 ? (
              dropdownOptions.map((item, index) => (
                <Menu.Item key={index + 1}>
                  {({ active }) => (
                    <p
                      className={`pt-2 pb-2 ps-2 pe-2 rounded-lg cursor-pointer ${
                        active ? "bg-primaryColor text-white" : ""
                      }`}
                      onClick={() => handleOptionSelect(item)}
                    >
                      {item.name}
                    </p>
                  )}
                </Menu.Item>
              ))
            ) : (
              <p className="pt-2 pb-2 ps-2 pe-2 rounded-lg">No options available</p>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
      {error && !isDisabled && <p className="input-error-txt">{errortxt}</p>}
    </div>
  );
};
