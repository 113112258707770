import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGE_URL } from "../../../core/Constant";

export const EventDetailsModal = ({ isOpen, onClose, eventDetails }) => {
  const cancelButtonRef = useRef(null);

  const interviewMembers = eventDetails?.InterviewMembers || [];

  console.log(eventDetails,'event detailss')

  return (
    <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white w-full min-w-[600px] text-left shadow-xl transition-all sm:max-w-lg">
              <div className="bg-white p-6">
              <div className="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                        <span className="text-lableColor font-DM font-[500] ml-5 text-[28px]">
                           Interview Details
                        </span>
                        <CloseButton onClick={onClose} />
                      </div>

                <div className="mt-4">
                  <p><strong>Title:</strong> {eventDetails?.title}</p>
                  <p><strong>Date:</strong> {eventDetails?.date}</p>
                  <p><strong>Time:</strong> {eventDetails?.time_from} - {eventDetails?.time_to}</p>
                  <p><strong>Location:</strong> {eventDetails?.location}</p>
                  <p><strong>Interview Type:</strong> {eventDetails?.interview_type}</p>
                  <p><strong>Time Zone:</strong> {eventDetails?.timezone}</p>
                </div>

                <div className="mt-6">
                  <h4 className="font-semibold">Interview Members</h4>
                  <div className="space-y-2 mt-2">
                    {interviewMembers.length > 0 ? (
                      interviewMembers.map((member, index) => (
                        <div key={index} className="flex items-center space-x-4">
                          <img
                            src={`${IMAGE_URL}${member?.User?.profile_image}`}
                            alt="Profile"
                            className="w-10 h-10 rounded-full"
                          />
                          <div>
                            <p className="text-sm font-medium">
                              {member?.User?.display_name || `${member?.User?.first_name || ""} ${member?.User?.last_name || ""}`.trim() || "No Name"}
                            </p>
                            <p className="text-sm text-gray-500">{member?.User?.email}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No members found for this interview.</p>
                    )}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  );
};
