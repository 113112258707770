import React, { useEffect, useState } from "react";
// import { IMAGES } from "../../../../assets";
import {
  CareerAdviceCard,
  CornerColumnHeading,
  DashboardCard,
  JobCard,
  NotesCard,
  SubHeading,
  UpskillCard,
} from "../../../components/molecule";
import {
  ButtonComponent,
  OutlineButtonComponent,
} from "../../../components/atoms";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import _ from "lodash";
import { IMAGES } from "../../../assets";
import { NotesModal, ProfileViewModal } from "../../../components/molecule/Modals";
import { AddNotesModal } from "../../../components/molecule/Modals/AddNotesModal";
import { getLoginCount } from "../../../core/UtilMethods";
import { useNavigate } from "react-router-dom";

const DashboardScreen = ({
  getDashboardDetails,
  getAllNotes,
  dashboardData,
  notesList,
  getProfileViews,
  profileViews
}) => {
  const [jobs, setJobs] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [open, setOpen] = useState(false);
  const [openProfileView, setOpenProfileView] = useState(false);
  const loginCount = getLoginCount();
  const navigate = useNavigate()
  

  useEffect(() => {
    getDashboardDetails();
    getAllNotes();
    getProfileViews();
  }, []);

  useEffect(() => {
    setJobs(_.get(dashboardData, "jobs"));
    setUserNotes(_.get(dashboardData, "usernotes"));
    console.log('profile views: ', profileViews);
  }, [dashboardData, profileViews]);

  return (
    <div>
      <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
        <div className="col-span-12 2xl:col-span-9 xl:col-span-9 lg:col-span-9 md:col-span-12 sm:col-span-12 ">
          
          { loginCount == 1 ? <div className="banner-card-container">
          <span className="text-4xl text-white text-center font-DM font-semibold">
            Your dream job unlocked,
            <br /> Explore exciting opportunities
          </span>
          <span className=" text-center mt-4 text-white font-DM  font-normal">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used <br /> to demonstrate the visual form
          </span>

          <div className="button-container">
            <div className="">
              <ButtonComponent
                background="bg-white"
                borderColor="border-white"
                fontColor="text-primaryColor"
                text="Get started"
              />
            </div>
            <div className="">
              <OutlineButtonComponent isPrimary={false} text="Learn more" />
            </div>
          </div>
        </div> :  <div className="welcome-back-dashboard">
            <p className="sub-heading">Welcome Back!</p>
            <div className="dashboard-cards-container ">
              <div className="">
                <DashboardCard
                  icon={IMAGES.DASHBOARD.SUITCASE_ICON}
                  headingText={"Active jobs in pipeline"}
                  color={"blue"}
                  count={
                    _.get(dashboardData, "activeJobCount") < 10
                      ? `0${_.get(dashboardData, "activeJobCount")}`
                      : _.get(dashboardData, "activeJobCount")
                  }
                />
              </div>
              <div className="">
                <DashboardCard
                  icon={IMAGES.DASHBOARD.PROFILE_ICON}
                  headingText={"Accepted Applications"}
                  color={"purple"}
                  count={
                    _.get(dashboardData, "appliedJobCount") < 10
                      ? `0${_.get(dashboardData, "appliedJobCount")}`
                      : _.get(dashboardData, "appliedJobCount")
                  }
                />
              </div>
              <div className="" onClick={() => setOpenProfileView(true)}>
                <DashboardCard
                  icon={IMAGES.DASHBOARD.EYE_ICON}
                  headingText={"Profile views"}
                  color={"green"}
                  count={
                    _.get(dashboardData, "profileViewCount") < 10
                      ? `0${_.get(dashboardData, "profileViewCount")}`
                      : _.get(dashboardData, "profileViewCount")
                  }
                />
              </div>
            </div>
          </div>}
         

         

          <div className="suggested-positions-section mt-8">
            <SubHeading
              title={"Positions that might be right for you"}
              onClick={()=>navigate(`/view_all/${'suggested_jobs'}`)}
              buttonText={"Explore More"}
            />
            <div className="job-listing-container">
              {jobs?.map((item, index) => {
                return (
                  <div className="col-span-1" key={index + 1}>
                    <JobCard item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="suggested-positions-section mt-8">
            <SubHeading
              title={"Upskill with courses"}
              buttonText={"Explore More"}
            />
            <div className="mt-6 w-full overflow-x-auto">
              <swiper-container
                slides-per-view="3"
                mousewheel-force-to-axis="true"
                // scrollbar="true"
              >
                {_.map(cousrseUpskill,'data',((course, index)=>{return(
                <swiper-slide key={index + 1}>
                <UpskillCard />
              </swiper-slide>
              )}))}
              </swiper-container>
            </div>
          </div> */}

          {/* <div className="suggested-positions-section mt-8">
          <SubHeading
            title={"Upskill with courses"}
            buttonText={"Explore More"}
          />
          <div className="job-listing-container">
            {Array.from({ length: 3 }).map((_, index) => {
              return <JobCard key={index + 1} />;
            })}
          </div>
        </div> */}
          {/* <div className="suggested-positions-section mt-8">
            <SubHeading title={"Career advices"} buttonText={"Explore More"} />
            <div className="job-listing-container">
              {Array.from({ length: 3 }).map((_, index) => {
              return <CareerAdviceCard key={index} />;
            })}
            </div>
          </div> */}
        </div>
        <div className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-12 sm:col-span-12 ">
          <CornerColumnHeading heading={"Notes"} buttonTxt={"View all"} />
          <div
            onClick={() => setOpen(true)}
            className="bg-white p-2 mt-2 cursor-pointer rounded-md w-full flex justify-between items-center"
          >
            <p className="font-DM text-primaryColor ">Add a note</p>
            <img src={IMAGES.COMMON.PLUSE_SIGN} alt="plus" />
          </div>
          <div className="corner-column">
            {_.map(userNotes, (note, index) => {
              return <NotesCard key={index + 1} details={note} />;
            })}
          </div>
        </div>
      </div>

      <AddNotesModal open={open} onClose={() => setOpen(false)} />
      <ProfileViewModal data={profileViews} open={openProfileView} onClose={()=>setOpenProfileView(false)} />
    </div>
  );
};

export default connect(
  (state) => ({
    dashboardData: state.home.get("dashboard"),
    notesList: state.home.get("candidateNotes"),
    profileViews:state.home.get("profileViews")
  }),
  {
    getDashboardDetails: Actions.home.getDashboardDetails,
    getAllNotes: Actions.home.getAllNotes,
    getProfileViews : Actions.home.getProfileViews
  }
)(DashboardScreen);
