import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../core/modules/Actions';
import { JobCard } from '../../../components';

export const ExploreMoreScreen = () => {
    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.jobs.get("candidateAllJobs"));
  
    useEffect(() => {
      dispatch(Actions.jobs.getAllJobCandidtes());
    }, [dispatch]);
  
    useEffect(() => {
    }, [jobs]);
  return (
    <div className="subscreen-container">
    <div className="flex flex-col ">
      <span class="text-lableColor font-DM text-3xl font-bold">
        Jobs which match your profile 
      </span>
    </div>

    <div className="job-listing-container">
      {jobs?.map((item, index) => {
        return (
          <div className="col-span-1" key={index + 1}>
            <JobCard item={item} />
          </div>
        );
      })}
    </div>
  </div>
  )
}
