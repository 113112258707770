import Repository from "./Repository";
import _ from "lodash";

const getPathRecruiter = (path)=>{
    return `recruiter/${path}`;
}

const getPath = (path)=>{
    return `user/${path}`;
}

//API CALL for Auth flow
class AuthRepository extends Repository {
    //call user login api pass password and email as params
    logIn = async (params) => {
        try {
            const data = await this.postData(getPath('login'), {...params});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    recruiterlogin = async (params) => {
        const appVersion = _.get(window,'navigator.appVersion','');
        const vendor = _.get(window,'navigator.vendor','')
        try {
            const data = await this.postData(getPathRecruiter('login'), {...params,device:vendor, browser:appVersion});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    // verify email
    onVerifyEmail = async (params) => {
        try {
            const data = await this.postData(getPathRecruiter('emailVerify'), params);
            return _.get(data, `data`);

        } catch (error) {
            return { error }
        }
    };

   
    
    //(1) call api for forgot password 
    forgotPassword = async (params) => {
        try {
            const data = await this.postData(getPath('forgot-password'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };


    

    // call api for rest password (4 FP)
    resetForgotPassword = async (params, urlToken) => {
        try {
            const data = await this.putData(getPath(`reset-password/${urlToken}`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };

   
    // signOut user
    onSignOut = async ()=>{
        try {
            const data = await this.postData(getPath('logout'));
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

     // call api for rest password (4 FP)
     changePassword = async (params) => {
        try {
            const data = await this.postData(getPathRecruiter(`change-password`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };

    getAllCourseDetails = async (params) =>{
        try {
            const data = await this.getData(getPathRecruiter(`detailFetch`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    recruitersignUp = async (params) =>{
        console.log(params,'signup paraamsss')
        try {
            const data = await this.postData(`recruiter/register`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }


    verifyToken = async ()=>{
        try{
            const data = await this.getData(getPathRecruiter(`token/userDetails`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    registerCandidate = async (params) => {
        try {
            const data = await this.postData(getPath(`register`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };


    candidateSignupComplete = async (params) => {
        try {
            const data = await this.postData(getPath(`candidatesignupcompletion`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };


    getLocationList = async () => {
        try {
            const data = await this.getData(getPath('locations'));
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }


}

export default new AuthRepository("auth");