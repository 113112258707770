import { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import {
  ButtonComponent,
  TagComponent,
  TextFieldComponent,
} from "../../atoms";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { TextEditorComponent } from "../TextEditor/TextEditorComponent";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { onToastNotify } from "../../../core/Constant";

export const EditJobFormTwo = ({jobId,onClose}) => {
  const [editorContent, setEditorContent] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const skills = useSelector((state) => state.common.get('skillData'));
  const createJob = useSelector((state) => state.home.get('createJob'));
  const jobPost = useSelector((state) => state.jobs.get('jobPost'));
  const dispatch = useDispatch();

  const userid =     localStorage.getItem('user_id');

  useEffect(() => {
    dispatch(Actions.common.getskillList());
    if(jobId){
        dispatch(Actions.jobs.getJobPost(jobId));
    }
  }, [dispatch]);

  useEffect(()=>{
    console.log(jobPost.description,'jobposstttt')
    setEditorContent(jobPost?.description)
    setSelectedSkills(jobPost?.Skills)
  },[jobPost])

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter skills based on input
    const filtered = skills.filter(skill =>
      skill.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSkills(filtered);
  };

  const handleSkillSelect = (skill) => {
    if (!selectedSkills.some((selected) => selected.id === skill.id)) {
      setSelectedSkills([...selectedSkills, skill]);
    }
    setInputValue(""); // Clear input after selection
    setFilteredSkills([]); // Hide suggestions
  };

  const handleRemoveSkill = (idToRemove) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.filter((skill) => skill.id !== idToRemove)
    );
  };

  const handleSubmit = () => {
    const selectedSkillIds = selectedSkills.map((skill) => skill.id); // Extract skill IDs
    const jobData = {
      ...createJob, // Assuming createJob is an ImmutableJS object 
      description: editorContent, // Add editor content as description
      company_id:userid
    };

    const formData = new FormData();

    formData.append('title', createJob?.title);
    formData.append('work_type', createJob?.work_type?.name);
    formData.append('min_salary', createJob?.min_salary);
    formData.append('max_salary', createJob?.max_salary);
    formData.append('location', createJob?.location);
    formData.append('currency', 'LKR');
    formData.append('job_type', createJob?.job_type?.name);
    formData.append('job_image', createJob?.job_image);
    formData.append('company_id',userid);
    formData.append('description',editorContent);
    formData.append('is_edit',1)
    formData.append('id',jobId);
    selectedSkillIds.forEach((item, index) => {
      formData.append(`skills[${index}]`, item);
    });
  
    if(userid){
      dispatch(Actions.jobs.editJobpost(jobId, formData))
      .then(() => {
        onClose();
      })
      .catch(error => {
        console.error('Error updating job post:', error);
      });

    }
    else{
      onToastNotify('Error')
    }

  };
  

  // Config for LexicalComposer
  const initialConfig = {
    theme: {},
    onError: (error) => {
      console.error(error);
    },
  };

  return (
    <Card className="bg-white py-4 relative" shadow={false}>
      <LexicalComposer initialConfig={initialConfig}>
        <TextEditorComponent   editorContent={editorContent}   onChange={handleEditorChange} />
      </LexicalComposer>
      <div className="relative">
        <TextFieldComponent
          label="Add Skills"
          value={inputValue}
          onChange={handleInputChange}
        />
        {filteredSkills.length > 0 && (
          <ul className="suggestion-list">
            {filteredSkills.map((skill) => (
              <li
                key={skill.id}
                onClick={() => handleSkillSelect(skill)}
                className="suggestion-item"
              >
                {skill.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mt-3 mb-3 gap-2 flex justify-end">
        {selectedSkills.map((skill) => (
          <TagComponent
            key={skill.id}
            text={skill.name}
            id={skill.id}
            onClose={handleRemoveSkill}
          />
        ))}
      </div>
      <div className="mt-3 mb-3 flex justify-end">
        <div className="w-full 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 grid grid-flow-row-dense grid-cols-2 gap-2">
          <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1">
            <ButtonComponent text="Cancel" isPrimary={false} />
          </div>
          <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1">
            <ButtonComponent text="Save" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Card>
  );
};
