

import Repository from "./Repository";
import _ from "lodash";

const getPath = (path)=>{
    return `recruiter/${path}`;
}
const getStudentPath = (path)=>{
    return `student/${path}`;
}
//API CALL for Admin Dashboard flow
class HomeRepository extends Repository {
    getRecruiterDashboard = async (params) => {
        try {
            const data = await this.postData(getPath('/dashboard'), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    createJobpost= async (params) => {
        try {
            const data = await this.postData('jobpost/createjobpost', params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    searchCandidates = async (id,params) => {
        console.log(id,params,'paramsssssss')
        try {
            const data = await this.putData(getPath(`searchAppliedCandidate/${id}`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    createmcqQuestions = async (params) => {
        console.log(params,'paramsssssss')
        try {
            const data = await this.postData(`screeningQuestions/createscreeningquestion`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    createvideoQuestions = async (params) => {
        console.log(params,'paramsssssss')
        try {
            const data = await this.postData(`screeningQuestions/createscreeningquestion`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getScreeningQuestions =  async (id,params) => {
        console.log(params,'paramsssssss')
        try {
            const data = await this.getData(`screeningQuestions/getScreeningQuestionsForJob/${id}`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    deleteScreeningQuestions =  async (params) => {
        console.log(params,'paramsssssss')
        try {
            const data = await this.deleteData(`screeningQuestions/deletescreeningquestion`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    updateScreeningQestions =  async (params) => {
        console.log(params,'paramsssssss')
        try {
            const data = await this.putData(`screeningQuestions/updatescreeningquestion`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getQualifiedCandidates =  async (id) => {
        try {
            const data = await this.getData(getPath(`viewallqualifiedcandidates/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getAppliedCandidates =  async (id) => {
        try {
            const data = await this.getData(getPath(`viewallappliedcandidates/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getRejectedCandidates =  async (id) => {
        try {
            const data = await this.getData(getPath(`viewallrejectedcandidates/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getOfferedCandidates =  async (id) => {
        try {
            const data = await this.getData(getPath(`getJobOfferedCandidates/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    
    getOfferAcceptededCandidates =  async (id) => {
        try {
            const data = await this.getData(getPath(`viewallacceptedcandidates/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getOfferRejectedCandidates =  async (id) => {
        try {
            const data = await this.getData(getPath(`getAllOfferRejectedCandidates/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    markasQualified = async (id) => {
        console.log(id,'iddddsdsds')
        try {
            const data = await this.putData(getPath(`qualifycandidate/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    markasRejected = async (id) => {
        try {
            const data = await this.putData(getPath(`rejectedcandidate/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    offerJob = async (id) => {
        console.log(id,'parammmmsss')
        try {
            const data = await this.putData(getPath(`makejoboffer/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    addInterview  = async (params) => {
        console.log(params,'interviewwww')
        try {
            const data = await this.postData(`interviews/createInterview`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    updateInterview  = async (id) => {
        try {
            const data = await this.postData(`interviews/updateInterview/${id}`, {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getInterviewList= async (id) => {
        try {
            const data = await this.getData(`interviews/getInterviewsByJob/${id}`, {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getInterviewDetails= async (id) => {
        try {
            const data = await this.getData(`interviews/getInterviewByIdWithFeedback/${id}`, {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    createConversation  = async (param) => {
        try {
            const data = await this.postData(`coversation/createconversation`, param);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getConversationList  = async (param) => {
        try {
            const data = await this.getData(`coversation/getconversationsforuser`, {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getMessages  = async (param) => {
        try {
            console.log(param,'paramssssss')
            const data = await this.postData(`coversation/getmessagesforconversation`, param);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    createMessage  = async (param) => {
        try {
            const data = await this.postData(`coversation/createmessage`, param);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    getAnswers  = async (param) => {
        try {
            const data = await this.postData(`screeningQuestions/getOneCandidateAnsweresForJob`, param);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };
    
    getSingleCandidate  = async (param) => {
        try {
            const data = await this.getData(`user/getuser/${param}`, {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    }





}

export default new HomeRepository("home");
