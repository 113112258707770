import React from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardLayout, MainLayout } from "../components/organism";
import OnboardingLayout from "../components/organism/Screen/OnboardingLayout";
import { ProfileLayout } from "../components/organism/Screen/ProfileLayout";
import AuthenticationScreen from "../module/auth/screen/AuthenticationScreen";
import { ResetPasswordScreen } from "../module/auth/screen/ResetPasswordScreen";
import CalenderScreen from "../module/home/screen/CalenderScreen";
import CreateJobScreen from "../module/home/screen/CreateJobScreen";
import DashboardRecruiterScreen from "../module/home/screen/DashboardRecruiterScreen";
import DashboardScreen from "../module/home/screen/DashboardScreen";
import { ExploreMoreScreen } from "../module/home/screen/ExploreMoreScreen";
import HiredScreen from "../module/home/screen/HiredScreen";
import InterviewRecruiterScreen from "../module/home/screen/InterviewRecruiterScreen";
import InterviewScreen from "../module/home/screen/InterviewScreen";
import MessageScreen from "../module/home/screen/MessageScreen";
import OffersScreen from "../module/home/screen/OffersScreen";
import QualifieldCandidateScreen from "../module/home/screen/QualifieldCandidateScreen";
import RecruiterMessageSceen from "../module/home/screen/RecruiterMessageSceen";
import { ScreeningQuestionsList } from "../module/home/screen/ScreeningQuestionsList";
import ScreeningQuestionsScreen from "../module/home/screen/ScreeningQuestionsScreen";
import { ScreeningQuestionsView } from "../module/home/screen/ScreeningQuestionsView";
import { JobOffersScreen } from "../module/jobs/screen/JobOffersScreen";
import MyJobScreen from "../module/jobs/screen/MyJobScreen";
import RecruiterManageJobScreen from "../module/jobs/screen/RecruiterManageJobScreen";
import JobSearchScreen from "../module/JobSearch/screen/JobSearchScreen";
import CandidateOnboardingProcess from "../module/onboardingProcess/screen/CandidateOnboardingProcess";
import OnboardingProcessScreen from "../module/onboardingProcess/screen/OnboardingProcessScreen";
import InviteFriendsScreen from "../module/profile/screen/InviteFriendsScreen";
import ProfileCvScreen from "../module/profile/screen/ProfileCvScreen";
import ProfileScreen from "../module/profile/screen/ProfileScreen";
import RecruiterProfileScreen from "../module/profile/screen/RecruiterProfileScreen";
import ResumeTemplateScreen from "../module/profile/screen/ResumeTemplateScreen";
import SettingScreen from "../module/profile/screen/SettingScreen";
import SkillScreen from "../module/profile/screen/SkillScreen";
import VideoRecordScreen from "../module/profile/screen/VideoRecordScreen";
import { ViewCoverLettersScreen } from "../module/profile/screen/ViewCoverLettersScreen";
import SalaryExpectationScreen from "../module/salary/screen/SalaryExpectationScreen";
import SalaryGrowthScreen from "../module/salary/screen/SalaryGrowthScreen";
import SalaryRangesScreen from "../module/salary/screen/SalaryRangesScreen";
import { PrivateRoute } from "./PrivateRoutes/PrivateRoute";
import { ViewResumeScreen } from "../module/profile/screen/ViewResumeScreen";

export const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route element={<OnboardingLayout />}>
          <Route path="/" index element={<AuthenticationScreen />} />
          <Route
            path="/onboarding-process"
            element={<OnboardingProcessScreen />}
          />
          <Route
            path="/onboarding-process-candidate"
            element={<CandidateOnboardingProcess />}
          />

          <Route path="/reset-password/*" element={<ResetPasswordScreen />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/interviews" element={<InterviewScreen />} />
            <Route
              path="/screening-questions"
              element={<ScreeningQuestionsList />}
            />
            <Route
              path="/screening-quiz-attempt/:company_name/:job_title"
              element={<ScreeningQuestionsView />}
            />
            <Route path="/calender" element={<CalenderScreen />} />
            <Route
              path="/recruiter_dashboard"
              element={<DashboardRecruiterScreen />}
            />
            <Route path="/recruiter_createjob" element={<CreateJobScreen />} />
            <Route
              path="/recruiter_screeningquestion"
              element={<ScreeningQuestionsScreen />}
            />
            <Route
              path="/recruiter_qualifiedcandidate"
              element={<QualifieldCandidateScreen />}
            />
            <Route path="/recruiter_offersscreen" element={<OffersScreen />} />
            <Route
              path="/recruiter_interviewscreen"
              element={<InterviewRecruiterScreen />}
            />
            <Route
              path="/recruiter_messagescreen"
              element={<RecruiterMessageSceen />}
            />
            <Route
              path="/recruiter-profile"
              element={<RecruiterProfileScreen />}
            />
            <Route
              path="/recruiter-managejobs"
              element={<RecruiterManageJobScreen />}
            />
            <Route path="/recruiter-hired" element={<HiredScreen />} />
            <Route
              path="/salary_expectation"
              element={<SalaryExpectationScreen />}
            />
            <Route path="/salary_ranges" element={<SalaryRangesScreen />} />
            <Route path="/salary_growth" element={<SalaryGrowthScreen />} />
            <Route path="/job_offers" element={<JobOffersScreen />} />
            <Route path="/view_all/:suggested" element={<ExploreMoreScreen />} />
            <Route path="/messages" element={<MessageScreen />} />

          </Route>

          <Route element={<ProfileLayout />}>
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/invite-friends" element={<InviteFriendsScreen />} />
            <Route path="/skill-requrement" element={<SkillScreen />} />
            <Route path="/templates" element={<ResumeTemplateScreen />} />
            <Route path="/cv" element={<ProfileCvScreen />} />
            <Route path="/video" element={<VideoRecordScreen />} />
            <Route path="/settings" element={<SettingScreen />} />
            <Route path="/invite_friends" element={<InviteFriendsScreen />} />
            <Route path="/skill_requrement" element={<SkillScreen />} />
            <Route path="/view_all/resume_templates" element={<ViewResumeScreen />} />
            <Route path="/view_all/cover_letter_ideas" element={<ViewCoverLettersScreen />} />
          </Route>

          <Route element={<MainLayout />}>
            <Route path="/my-jobs" element={<MyJobScreen />} />
            <Route path="/job-search" element={<JobSearchScreen />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
