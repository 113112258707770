import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { ButtonComponent, DropdownComponent, TextFieldComponent } from '../../atoms'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimezoneSelect from 'react-timezone-select';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../core/modules/Actions';
import { getUserId } from '../../../core/UtilMethods';
export const InterviewPostModal = ({ jobId, onClose, isOpen = true,candidate }) => {
    const [open, setOpen] = useState(isOpen)
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()
    const jobPost = useSelector((state) => state.jobs.get('jobPost'));
    const [interviewtype, setInterviewType] = useState()
    const [selectedDate, setSelectedDate] = useState(new Date()) // For date picker
    const [timeFrom, setTimeFrom] = useState(new Date()) // For time picker (from)
    const [timeTo, setTimeTo] = useState(new Date()) // For time picker (to)
    const [timezone, setTimezone] = useState({ value: 'UTC' })
    const [room, setRoom] = useState()
    const [location, setLocation] = useState()
    const [title, setTitle] = useState()

    const interviewType = [
        { id: 1, name: 'On-line' },
        { id: 2, name: 'On-site' },
    ]

    const roomType = [
        { id: 1, name: 'Room-one' },
        { id: 2, name: 'Room-two' },
        { id: 3, name: 'Room-three' },
    ]

    const handleJobtype = (option) => {
        setInterviewType(option)
    }

    const handleRoomtype = (option) => {
        setRoom(option)
    }

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (jobId && open) {
            dispatch(Actions.jobs.getJobPost(jobId))
        }
    }, [jobId, open, dispatch])

    const handleClose = () => {
        setOpen(false)
        onClose()
    }

    const handleSubmit = () => {
        const data = {
            job_id: jobId,
            title: title,
            interview_type: interviewtype?.name || '',
            candidate_user_id: candidate.Candidate.user_id,
            date: selectedDate.toISOString().split('T')[0],
            time_from: timeFrom.toTimeString().split(' ')[0], 
            time_to: timeTo.toTimeString().split(' ')[0], 
            timezone: timezone.value,
            location: location || '',
            status: 1,
            members: [
                { user_id:candidate.Candidate.user_id, status: 1 },
                { user_id: parseInt(getUserId()), status: 1 },
            ],
        }
        console.log('Form Submitted: ', data)
        dispatch(Actions.home.addInterview({      job_id: jobId,
            title: title,
            interview_type: interviewtype?.name || '',
            candidate_user_id: candidate.Candidate.user_id,
            date: selectedDate.toISOString().split('T')[0],
            time_from: timeFrom.toTimeString().split(' ')[0], 
            time_to: timeTo.toTimeString().split(' ')[0], 
            timezone: timezone.value,
            location: location || '',
            status: 1,
            members: [
                { user_id:candidate.Candidate.user_id, status: 1 },
                { user_id: parseInt(getUserId()), status: 1 },
            ],}))
        handleClose()
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 flex items-center justify-center"
                initialFocus={cancelButtonRef}
                onClose={handleClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="relative w-full max-w-4xl">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <Dialog.Panel className="w-full max-w-4xl bg-white rounded-lg shadow-xl">
                                <div className="p-8 space-y-6">
                                    <div className="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                                        <div className="flex flex-row w-full">
                                            <span className="text-labelColor font-medium ml-5 text-[28px]">Schedule an Interview</span>
                                        </div>
                                        <CloseButton onClick={onClose} />
                                    </div>
                                </div>
                                <form className="mb-2 grid grid-cols-5 p-8">
                                    <div className="w-full flex flex-col col-span-3 items-start">
                                        <div className="w-full flex flex-row   justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">Type</span>
                                            </div>
                                            <DropdownComponent
                                                error={false}
                                                placeholder="Select interview type"
                                                dropdownOptions={interviewType}
                                                value={interviewtype}
                                                onOptionSelected={handleJobtype}
                                            />
                                        </div>
                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">Room</span>
                                            </div>
                                            <DropdownComponent
                                                error={false}
                                                placeholder="Select room"
                                                dropdownOptions={roomType}
                                                value={room}
                                                onOptionSelected={handleRoomtype}
                                            />
                                        </div>
                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">Date</span>
                                            </div>
                                            <DatePicker
                                                selected={selectedDate}
                                                className={"input-field-basic"}
                                                onChange={(date) => setSelectedDate(date)}
                                                dateFormat="yyyy-MM-dd"
                                                wrapperClassName="w-full"
                                            />
                                        </div>

                                        {/* From Time */}
                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">From</span>
                                            </div>
                                            <DatePicker
                                                selected={timeFrom}
                                                className={"input-field-basic"}
                                                onChange={(time) => setTimeFrom(time)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                wrapperClassName="w-full"


                                            />
                                        </div>

                                        {/* To Time */}
                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">To</span>
                                            </div>
                                            <DatePicker
                                                selected={timeTo}
                                                onChange={(time) => setTimeTo(time)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                className={"input-field-basic"}
                                                wrapperClassName="w-full"

                                            />
                                        </div>

                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">Time Zone</span>
                                            </div>
                                            <TimezoneSelect
                                                value={timezone}
                                                onChange={setTimezone}
                                                className={"input-field-basic"}
                                                wrapperClassName="w-full"

                                            />
                                        </div>
                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">Location</span>
                                            </div>
                                            <TextFieldComponent
                                                error={false}
                                                placeholder="Enter location"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                            />
                                        </div>
                                        <div className="w-full flex flex-row justify-between">
                                            <div className="w-full">
                                                <span className="input-label-txt">Event Title</span>
                                            </div>
                                            <TextFieldComponent
                                                error={false}
                                                placeholder="Enter event title"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                       
                                    </div>
                                    <div className="w-full flex flex-row col-span-5 justify-end">
                                           <div className='mr-2'>
                                           <ButtonComponent isPrimary={false} text="Cancel" onClick={handleClose} />
                                           </div>
                                           <div>
                                           <ButtonComponent text="Send" onClick={handleSubmit} />
                                           </div>
                                        </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
