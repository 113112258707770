import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonComponent, TextFieldComponent } from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";

export const ConversationModal = ({ isOpen, onClose, onSubmit }) => {
  const cancelButtonRef = useRef(null);
  const [conversationTitle, setConversationTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState(false);

  // Handle title input change
  const onTitleChange = (e) => {
    const text = e.target.value;
    setConversationTitle(text);
    setErrorTitle(text.trim().length === 0);
  };

  // Clear form on close or submit
  const handleClear = () => {
    setConversationTitle("");
    setErrorTitle(false);
  };

  // Handle submit action
  const handleSubmit = () => {
    if (conversationTitle.trim()) {
      onSubmit(conversationTitle); // Call onSubmit with conversation title
      handleClear(); // Clear form after submission
      onClose(); // Close modal
    } else {
      setErrorTitle(true); // Show error if title is empty
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black w-full min-w-[600px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                        <span className="text-lableColor font-DM font-[500] ml-5 text-[28px]">
                          Create Conversation
                        </span>
                        <CloseButton onClick={onClose} />
                      </div>

                      <div className="w-full my-8 flex flex-col items-start">
                        <TextFieldComponent
                          error={errorTitle}
                          placeholder="Enter conversation title"
                          isdisabled={false}
                          errortxt="Title is required!"
                          label="Conversation Title*"
                          value={conversationTitle}
                          onChange={(e) => onTitleChange(e)}
                        />
                      </div>

                      <div className="mt-2 w-full flex items-center justify-center flex-row gap-3 rounded-[16px]">
                        <ButtonComponent
                          isPrimary={false}
                          text="Clear"
                          onClick={handleClear}
                        />
                        <ButtonComponent
                          text="Create"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
