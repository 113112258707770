import React, { useEffect, useState } from "react";
import { ButtonComponent, TabButtonComponent, TextFieldComponent } from "../../../components/atoms";
import { ScreeningCard } from "../../../components/molecule";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { onToastNotify } from "../../../core/Constant";
import { imageURL } from "../../../core/repository/Repository";
import { InterviewPostModal } from "../../../components/molecule/Modals/InterviewPostModal";
import { AnswerViewModal, ConversationModal } from "../../../components/molecule/Modals";
import { IMAGE_URL } from "../../../core/Constant";

const QualifiedCandidiateScreen = ({
  isInitial = false,
  getAllJobRecruiter,
  getQualifiedCandidates,
  getRejectedCandidates,
  getAppliedCandidates,
  recruiterJobs,
  appliedList,
  qualifiedList,
  rejectedList,
  markasQualified,
  markasRejected,
  offerJob,
  createConversation,
  getAnswers,
  answersCandidiate
}) => {
  const [activeTab, setActiveTab] = useState("applied");
  const [jobPost, setJobPost] = useState("");
  const [jobs, setJobs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [appliedCandidates, setAppliedCandidates] = useState([]);
  const [qualifiedCandidates, setQualifiedCandidates] = useState([]);
  const [rejectedCandidates, setRejectedCandidates] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [conversationTitle, setConversationTitle] = useState("");
  const [isAnswerModal,setIsAnswerModal] = useState(false)
  const [answers,setAnswers] = useState([])



  useEffect(() => {
    getAllJobRecruiter();
  }, []);

  useEffect(() => {
    setJobs(recruiterJobs || []);
  }, [recruiterJobs]);

  const handleJobPostChange = (e) => {
    setJobPost(e.target.value);
    setShowDropdown(true);
  };

  const handleJobPostSelect = (job) => {
    console.log(job)
    setJobPost(job.title);
    setSelectedJob(job);
    getAppliedCandidates(job.id);
    setShowDropdown(false);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setCandidateList([]);

    if (selectedJob) {
      if (tabName === "applied") {
        getAppliedCandidates(selectedJob.id);
      } else if (tabName === "qualified") {
        getQualifiedCandidates(selectedJob.id);
      } else if (tabName === "rejected") {
        getRejectedCandidates(selectedJob.id);
      }
    } else {
      onToastNotify("Please Select Job");
    }
  };

  useEffect(() => {
    if (activeTab === "applied" && selectedJob) {
      getAppliedCandidates(selectedJob.id);
    }
  }, [activeTab, selectedJob]);

  useEffect(() => {
    setAppliedCandidates(appliedList || []);
    if (activeTab === "applied") {
      setCandidateList(appliedList || []);
    }
    setQualifiedCandidates([]);
    setRejectedCandidates([]);
  }, [appliedList]);

  useEffect(() => {
    setQualifiedCandidates(qualifiedList || []);
    if (activeTab === "qualified") {
      setCandidateList(qualifiedList || []);
    }
    setAppliedCandidates([]);
    setRejectedCandidates([]);
  }, [qualifiedList]);

  useEffect(() => {
    setRejectedCandidates(rejectedList || []);
    if (activeTab === "rejected") {
      setCandidateList(rejectedList || []);
    }
    setAppliedCandidates([]);
    setQualifiedCandidates([]);
  }, [rejectedList]);

  const handleOpenModal = (job) => {
    setSelectedJobId(job.job_id);
    setSelectedCandidate(job);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedJobId(null);
  };

  const onCreateConversation = (jobId, candidateId) => {
    setSelectedJobId(jobId);
    setSelectedCandidate(candidateId);
    setIsConversationModalOpen(true);
  };

  const handleCreateConversation = (title) => {
    if (title && selectedJobId && selectedCandidate) {
      createConversation({ name: title, job_id: selectedJobId, candidate_id: selectedCandidate });
      setIsConversationModalOpen(false);
    } else {
      onToastNotify("Please provide a valid title.");
    }
  };

  const onViewAnswers  = (jobId, candidateId) => {
    setSelectedJobId(jobId);
    setSelectedCandidate(candidateId);
    console.log(jobId,candidateId)
    getAnswers({user_id:candidateId,job_id:jobId})
    setIsAnswerModal(true);
  };


  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        <div className="bg-white p-4 rounded-xl">
          <p className="text-2xl text-darkBlue font-bold font-DM">Candidates</p>

          <div className="relative">
            <TextFieldComponent
              placeholder="Please Select Job Post"
              value={jobPost}
              onChange={handleJobPostChange}
              label="Job Post"
            />
            {showDropdown && jobPost && (
              <div className="absolute z-10 bg-white shadow-md max-h-40 overflow-y-auto w-full">
                {jobs
                  .filter((job) => job.title.toLowerCase().includes(jobPost.toLowerCase()))
                  .map((job, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-blue-200"
                      onClick={() => handleJobPostSelect(job)}
                    >
                      {job.title}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {selectedJob && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-100 flex items-start">
              <img
                src={IMAGE_URL + selectedJob.job_image}
                alt={`${selectedJob?.title} image`}
                className="w-20 h-20 rounded-full object-cover mr-4"
              />
              <div>
                <p>
                  <strong>Job Title:</strong> {selectedJob.title}
                </p>
                <p>
                  <strong>Job Description:</strong> {selectedJob.description}
                </p>
                <p>
                  <strong>Location:</strong> {selectedJob.location}
                </p>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-y-2 flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row mt-2">
            <div>
              <TabButtonComponent
                buttonOneName="Applied"
                buttonTwoName="Qualified Candidates"
                buttonThreeName="Rejected"
                isApplied={activeTab === "applied"}
                isThirdTab={activeTab === "rejected"}
                onClick={handleTabChange}
              />
            </div>
          </div>
        </div>

        {candidateList.length > 0 ? (
          candidateList.map((item, index) => (
            <ScreeningCard
              onCreateConversation={() => onCreateConversation(selectedJob.id, item.Candidate.user_id)}
              onViewAnswers={() => onViewAnswers(selectedJob.id, item.Candidate.user_id)}
              onOfferJob={() => offerJob(item.id)}
              onInterview={() => handleOpenModal(item)}
              key={index}
              candidate={item}
              onMarkAsQualified={() => markasQualified(item.id)}
              onMarkAsRejected={() => markasRejected(item.id)}
              tabName={activeTab} />
          ))
        ) : (
          <p className="text-lg mt-2 text-gray-700 font-semibold p-4 bg-white rounded-lg shadow-md border border-gray-200">
            No candidates available.
          </p>
        )}
      </div>

      {isModalOpen && (
        <InterviewPostModal
          jobId={selectedJobId}
          isOpen={isModalOpen}
          candidate={selectedCandidate}
          onClose={handleCloseModal}
        />
      )}

      {isConversationModalOpen && (
        <ConversationModal
          isOpen={isConversationModalOpen}
          onClose={() => setIsConversationModalOpen(false)}
          onSubmit={handleCreateConversation}
        />
      )}
      {isAnswerModal && (
        <AnswerViewModal
          isOpen={isAnswerModal}
          onClose={() => setIsAnswerModal(false)}
          data={answersCandidiate}
         // onSubmit={handleCreateConversation}
        />
      )}

    </div>
  );
};

export default connect(
  (state) => ({
    recruiterJobs: state.jobs.get("recruiterJobs"),
    appliedList: state.home.get("appliedList"),
    qualifiedList: state.home.get("qualifiedList"),
    rejectedList: state.home.get("rejectedList"),
    answersCandidiate: state.jobs.get("answersCandidiate")
  }),
  {
    getAllJobRecruiter: Actions.jobs.getAllJobRecruiter,
    getAppliedCandidates: Actions.home.getAppliedCandidates,
    getQualifiedCandidates: Actions.home.getQualifiedCandidates,
    getRejectedCandidates: Actions.home.getRejectedCandidates,
    markasQualified: Actions.home.markasQualified,
    markasRejected: Actions.home.markasRejected,
    offerJob: Actions.home.offerJob,
    createConversation: Actions.home.createConversation,
    getAnswers: Actions.home.getAnswers,
  }
)(QualifiedCandidiateScreen);
