import { createAction } from "../../core/AppUtils";
import JobRepository from "../../core/repository/JobRepository";

export const ModuleEvents = {
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  NETWORK_ERROR:'NETWORK_ERROR',
  SAVE_JOB: "SAVE_JOB",
  REMOVE_SAVED_JOB: "REMOVE_SAVED_JOB",
  GET_ALL_JOB_CANDIDATE: "GET_ALL_JOB_CANDIDATE",
  GET_JOB_POST: "GET_JOB_POST",
  APPLY_JOB: "APPLY_JOB",
  GET_ALL_APPLYED_JOBS_CANDIDATE: "GET_ALL_APPLYED_JOBS_CANDIDATE",
  GET_ALL_SAVED_JOBS_CANDIDATE: "GET_ALL_SAVED_JOBS_CANDIDATE",
  GET_ALL_JOB_RECRUITER:'GET_ALL_JOB_RECRUITER',
  SET_JOBSTATUS_RECRUITER:'SET_JOBSTATUS_RECRUITER',
  EDIT_JOB_POST:'EDIT_JOB_POST',
  GET_ALL_JOB_TITLES:'GET_ALL_JOB_TITLES',
  GET_JOB_OFFERS:'GET_JOB_OFFERS',
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, error => error),
  loadingStarted: createAction(ModuleEvents.LOADING_STARTED, action => action),
  loadingFinished: createAction(ModuleEvents.LOADING_FINISHED, action => action),

  saveJob: createAction(ModuleEvents.SAVE_JOB, async (jobId) => {
    return await JobRepository.saveJob(jobId);
  }),
  removeSavedJob: createAction(ModuleEvents.REMOVE_SAVED_JOB, async () => {
    return await JobRepository.removeSavedJob();
  }),
  getAllJobCandidtes: createAction(ModuleEvents.GET_ALL_JOB_CANDIDATE, async () => {
    return await JobRepository.getAllJobsCandidate();
  }),
  getJobPost: createAction(ModuleEvents.GET_JOB_POST, async (jobId) => {
    return await JobRepository.getAJobPost(jobId);
  }),
  applyJob: createAction(ModuleEvents.APPLY_JOB, async (jobId, params) => {
    return await JobRepository.applyJob(jobId, params);
  }),
  getAllAppliedJobCandidate: createAction(ModuleEvents.GET_ALL_APPLYED_JOBS_CANDIDATE, async (params) => {
    return await JobRepository.getAllApplyedJobsCandidate(params);
  }),
  getAllSavedJobCandidate: createAction(ModuleEvents.GET_ALL_SAVED_JOBS_CANDIDATE, async (params) => {
    return await JobRepository.getAllSavedJobsCandidate(params);
  }),
  getAllJobRecruiter: createAction(ModuleEvents.GET_ALL_JOB_RECRUITER, async () => {
    return await JobRepository.getAllJobRecruiter();
  }),
  setRecruiterjobStatus : createAction(ModuleEvents.SET_JOBSTATUS_RECRUITER, async (jobId, params) => {
    return await JobRepository.setRecruiterjobStatus(jobId, params);
  }),

  editJobpost : createAction(ModuleEvents.EDIT_JOB_POST, async (jobId, params) => {
    return await JobRepository.editJobpost(jobId, params);
  }),

  getAllJobTitle : createAction(ModuleEvents.GET_ALL_JOB_TITLES, async () => {
    return await JobRepository.getAllJobTitles();
  }),

  getAllJobOffers : createAction(ModuleEvents.GET_JOB_OFFERS, async () => {
    return await JobRepository.suggestedJobs();
  }),

 
};



