import { createAction } from "../../core/AppUtils";
import ProfileRepository from "../../core/repository/ProfileRepository";

export const ModuleEvents = {
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_FINISHED: "LOADING_FINISHED",
  NETWORK_ERROR: "NETWORK_ERROR",
  GET_CANDIDATE_DETAILS: "GET_CANDIDATE_DETAILS",
  GET_RESUME_TEMPLATES: "GET_RESUME_TEMPLATES",
  GET_COVER_LETTER_TEMPLATES: "GET_COVER_LETTER_TEMPLATES",
  GET_CANDIDATE_CV: "GET_CANDIDATE_CV",
  UPDATE_CANDIDATE: "UPDATE_CANDIDATE",
  UPDATE_CANDIDATE_PROFILE_PIC: "UPDATE_cANDIDATE_PROFILE_PIC",
  UPLOAD_VIDEO: "UPLOAD_VIDEO",
  UPDATE_CANDIDATE_CV: "UPDATE_CANDIDATE_CV",
  CANDIDATE_PW_CHANGE: "CANDIDATE_PW_CHANGE",
  INVITE_FRIENDS: "INVITE_FRIENDS",
  GET_COMPANY_DETAILS:'GET_COMPANY_DETAILS',
  RECRUITER_EDIT:'RECRUITER_EDIT'
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, (error) => error),
  loadingStarted: createAction(
    ModuleEvents.LOADING_STARTED,
    (action) => action
  ),
  loadingFinished: createAction(
    ModuleEvents.LOADING_FINISHED,
    (action) => action
  ),

  getCandiateDetails: createAction(
    ModuleEvents.GET_CANDIDATE_DETAILS,
    async () => {
      return await ProfileRepository.getCandidateDetails();
    }
  ),
  getCompanyDetails: createAction(
    ModuleEvents.GET_COMPANY_DETAILS,
    async () => {
      return await ProfileRepository.getCompanyDetails();
    }
  ),
  getResumeTemplates: createAction(
    ModuleEvents.GET_RESUME_TEMPLATES,
    async () => {
      return await ProfileRepository.getResumeTemplates();
    }
  ),

  getCoverLetterTemplates: createAction(
    ModuleEvents.GET_COVER_LETTER_TEMPLATES,
    async () => {
      return await ProfileRepository.getCoverLetterTemplates();
    }
  ),
  getCandidateCv: createAction(ModuleEvents.GET_CANDIDATE_CV, async () => {
    return await ProfileRepository.getCandidateCv();
  }),

  updateCandidate: createAction(ModuleEvents.UPDATE_CANDIDATE, async (params) => {
    return await ProfileRepository.updateCandidate(params);
  }),
  updateCandidateProfilePhoto: createAction(
    ModuleEvents.UPDATE_CANDIDATE_PROFILE_PIC,
    async (params) => {
      return await ProfileRepository.updateCandidateProfilePhoto(params);
    }
  ),

  uploadVideo: createAction(ModuleEvents.UPLOAD_VIDEO, async (params,token, options) => {
    return await ProfileRepository.uploadVideo(params,token, options);
  }),
  updateCandidateCv: createAction(
    ModuleEvents.UPDATE_CANDIDATE_CV,
    async (params) => {
      return await ProfileRepository.updateCandidateCv(params);
    }
  ),

  candidatePwChange: createAction(
    ModuleEvents.CANDIDATE_PW_CHANGE,
    async (params) => {
      return await ProfileRepository.candidatePwChange(params);
    }
  ),

  candidatePwChange: createAction(
    ModuleEvents.CANDIDATE_PW_CHANGE,
    async (params) => {
      return await ProfileRepository.candidatePwChange(params);
    }
  ),
  
  recruiterEdit: createAction(
    ModuleEvents.RECRUITER_EDIT,
    async (id,params) => {
      return await ProfileRepository.recruiterEdit(id,params);
    }
  ),
  inviteFriends: createAction(ModuleEvents.INVITE_FRIENDS, async (params) => {
    return await ProfileRepository.inviteFriends(params);
  }),
};
