import React, { useState } from "react";
import {
  EditProfileForm,
  ProfileDetailCard,
  ResumeCard,
} from "../../../components";
import { ButtonComponent } from "../../../components/atoms";
import { PdfUploadCard } from "../../../components/molecule/Cards";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";

export default ({}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);

  const getFileSelected = (selectedFile, isFileSelected) => {
    setFile(selectedFile);
    setIsSelected(isFileSelected);
  };

  const handleOnPressRemove = () => {
    setFile(null);
    setIsSelected(false);
  };
  const handleUploadFile = async () => {
    if (file !== null && isSelected) {
      const blob = new Blob([file], { type: file.type });

      const data = new FormData();
      data.append("cv_url", blob, _.get(file, "name"));
      dispatch(
        Actions.profile.updateCandidateCv(
          data,
          { "Content-Type": "multipart/form-data" },
          (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log("percentage: ", percent);
          }
        )
      );
    }
  };
  return (
    <div className="subscreen-container">
      <div className="flex flex-col ">
        <span class="text-lableColor font-DM text-2xl font-bold">
          Update CV
        </span>
        <span class="text-ash font-DM text-md font-normal">
          In publishing and graphic design, Lorem ipsum is a placeholder
        </span>
      </div>
      <div className="w-96">
        <PdfUploadCard
          setFileNFileSelected={getFileSelected}
          file={file}
          isFileSelected={isSelected}
          removeSelectedFile={handleOnPressRemove}
        />
      </div>
      <div className="flex items-center justify-between gap-3 2xl:w-2/5 xl:w-2/3 lg:w-1/2 md:w-2/3 sm:w-full">
        <ButtonComponent isPrimary={false} text="Cancel" />
        <span class="text-lableColor font-DM text-md font-bold">OR</span>
        <ButtonComponent
          text="Upload selected file"
          onClick={handleUploadFile}
        />
      </div>
    </div>
  );
};
