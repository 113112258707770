import React, { useEffect, useState } from "react";
import { ButtonComponent, TabButtonComponent, TagComponent, TextAreaComponent, TextFieldComponent } from "../../../components/atoms";
import { JobPostEditModal, ScreeningCard } from "../../../components/molecule";
import { JobTable } from "../../../components";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

const RecruiterManageJobScreen = ({ isInitial = false,getAllJobRecruiter,recruiterJobs }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [selectedJobId, setSelectedJobId] = useState(null);  


    const [jobs,setJobs] = useState()

    useEffect(()=>{
        getAllJobRecruiter()
    },[])

    useEffect(()=>{ 
        setJobs(recruiterJobs)
    },[recruiterJobs])

    
       const handleEditClick = (jobId) => {
        setSelectedJobId(jobId);
        setIsModalOpen(true);  
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedJobId(null);  
    };

    return (
        <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
            <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
                <div className="bg-white p-4 rounded-xl">
                <p className="text-2xl text-darkBlue font-bold font-DM">Manage Jobs</p>
                <JobTable jobData={jobs} onEditClick={handleEditClick} /> 
                    </div>
            </div>
            {isModalOpen && <JobPostEditModal jobId={selectedJobId} onClose={handleCloseModal} />}

        </div>
    );
};

export default connect(
    (state) => ({
    //   dashboardDetails: state.home.get("dashboardDetails"),
    //   notesList: state.home.get("candidateNotes"),
    recruiterJobs:state.jobs.get("recruiterJobs")
    }),
    {
    //   getDashboardDetails: Actions.home.getDashboardDetails,
    //   getAllNotes: Actions.home.getAllNotes,
    getAllJobRecruiter:Actions.jobs.getAllJobRecruiter
    }
  )(RecruiterManageJobScreen);
  
