export const ModuleSet = [
  'home',
  'auth',
  'common',
  'jobs',
  'JobSearch',
  "auth",
  'onboardingProcess',
  "profile",
  // "salary",
];
