import React, { useState } from "react";
import { ButtonComponent, TextFieldComponent } from "../../../components/atoms";
import { EMAIL_REGEX, getText } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export default ({ }) => {
const dispatch = useDispatch();
const [email, setEmail] = useState('');
const [emailIsValid, setEmailIsvalid] = useState(false);
const [emailError, setEmailError] = useState(false);
    const onChangeEmail = (e) => {
        const text = getText(e);
        let isValid = false;
        const reg = EMAIL_REGEX;
        if (text.trim().length > 3) {
            isValid = reg.test(text.trim());
        }
        setEmail(text);
        setEmailIsvalid(isValid);
        setEmailError(false);
      };

      const onClickInvite = () => {
        if(emailIsValid && email){
            dispatch(Actions.profile.inviteFriends({email:email}));
        }else{
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter a valid email address",
              });
        }
        
      }


    return (
        <div className="subscreen-container">
            <div className="flex flex-col ">
                <span class="text-lableColor font-DM text-2xl font-bold">Invite Friends</span>
                <span class="text-ash font-DM text-sm font-normal">In publishing and graphic design, Lorem ipsum is a placeholder</span>
            </div>
            <div className="settings-input-wrapper">
                <div className="setting-col-span-items">
                    <TextFieldComponent
                    onChange={(e) => onChangeEmail(e)}
                    value={email}
                    error={emailError}
                    errortxt="Please enter a valid email address"
                    label="Email" placeholder="Type here"/>
                </div>

            </div>
            <div className="w-1/5">
                <ButtonComponent width="w-full" onClick={onClickInvite} padding="p-2" text="Send" isPrimary={true} />
            </div>

        </div>
    )
}