import React from 'react'
import { getToken } from '../../core/UtilMethods';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
    let auth = getToken();
    return (<>
        {auth ? <Outlet /> : <Navigate to="/" />}
    </>);
}
