import React from 'react';
import { Actions } from '../../core/modules/Actions';
import {ModuleEvents} from './Actions';
import { onToast } from '../../core/Constant';
// import {navigate} from '../../core/repository/Repository';

export default {
  [ModuleEvents.NETWORK_ERROR]: ({dispatch, payload, appState}) => {
    // Logger.infor(JSON.stringify(payload))
   },

   [ModuleEvents.SAVE_JOB]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 1) {
      onToast('Job Saved', payload, false);
    }
   
  },


  [ModuleEvents.APPLY_JOB]: ({ dispatch, payload, appState }) => {
    onToast('Application Submitted', payload, false);
  },
  [ModuleEvents.SET_JOBSTATUS_RECRUITER]: ({ dispatch, payload, appState }) => {
    if(payload && !payload.error && payload.status_code == 1){
        dispatch(Actions.jobs.getAllJobRecruiter())
    }
    onToast('Job Status', payload, false);
  },
  [ModuleEvents.EDIT_JOB_POST]: ({ dispatch, payload, appState }) => {
    if(payload && !payload.error && payload.status_code == 1){
        dispatch(Actions.jobs.getAllJobRecruiter())
    }
    onToast('Job Status', payload, false);
  },
  
};
