import React, { useEffect } from "react";
import { IMAGES } from "../../../assets/images";
import { Avatar } from "@material-tailwind/react";
import _ from "lodash";
import { dateFromNow, IMAGE_URL, USER_ROLE } from "../../../core/Constant";
import { imageURL } from "../../../core/repository/Repository";
import { getUserType } from "../../../core/UtilMethods";

export const MessageCard = ({
  data,
  onClose,
  close = false,
  onClick = () => {},
}) => {
  useEffect(() => {}, [data]);
  return (
    <div
      class="relative flex flex-row items-center p-4 cursor-pointer"
      onClick={onClick}
    >
      <div class="absolute text-xs text-ash font-medium text-[12px] font-DM right-0 top-0 mr-4 mt-3">
        {dateFromNow(_.get(data, "lastMessageTime"))}
      </div>
      <Avatar
        src={
          getUserType() == USER_ROLE.recruiter
            ? IMAGE_URL + _.get(data, "member.profile_image")
            : IMAGE_URL + _.get(data, "member.profile_image")
        }
        alt="avatar"
      />
      <div class="flex flex-col flex-grow ml-3">
        <div class="text-sm text-lableColor font-DM text-[14px] font-bold">
          {_.get(data, "name")}
        </div>
        <div class="text-xs truncate  text-ash font-normal font-DM text-[12px] w-40">
          {_.get(data, "lastMessage")}
        </div>
      </div>
      {_.get(data, "unreadCount") > 0 ? (
        <div class="flex-shrink-0 ml-2 self-end mb-1">
          <span class="flex items-center justify-center h-5 w-5 bg-red text-white font-DM text-xs rounded-full">
            {_.get(data, "unreadCount")}
          </span>
        </div>
      ) : null}
    </div>
  );
};
