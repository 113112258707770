import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AUTH_PROCESS_STEPS } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import {
  ButtonComponent,
  HeadingComponent,
  StepperComponent,
  TagComponent,
} from "../../atoms";
import { TypeAheadSearch } from "../../atoms/Inputs/TypeAhead/TypeAheadSearch";

export const CandidateStepOne = ({
  listData = [],
  // getFinalLocations,
  onClickLogin = () => {},
}) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [error, setError] = useState(false);
  const [errortxt, setErrorTxt] = useState("");
  const dispatch = useDispatch();

  const selectedItem = (item) => {
    setSelectedLocations([...selectedLocations, item]);
    // getFinalLocations([...selectedLocations, item]);
    setError(false);
  };

  const closeSelectedTag = (id) => {
    const arr = _.filter(selectedLocations, (item) => _.get(item, "id") !== id);
    setSelectedLocations(arr);
    // getFinalLocations(arr);
  };

  const onClickContinue = () => {
    if (selectedLocations.length > 0) {
      if (selectedLocations.length > 1) {
        dispatch(
          Actions.auth.storeUserDetails({ locations: selectedLocations })
        );
        setError(false);
        dispatch(
          Actions.auth.authStep({
            step: AUTH_PROCESS_STEPS.onboarding_pick_jobType_can,
          })
        );
      } else {
        setError(true);
        setErrorTxt("Please select multiple locations");
      }
    } else {
      setError(true);
      setErrorTxt("Please select locations");
    }
  };

  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <StepperComponent totalSteps={3} currentStep={1} />
      </div>
      <HeadingComponent
        headingText={"Where do you want to work?"}
        description={"In publishing and graphic design, Lorem ipsum"}
      />

      <div className="w-full">
        <div className="mb-4">
          <TypeAheadSearch
            error={error}
            errortext={errortxt}
            listData={listData}
            selectedItem={selectedItem}
            placeholder={"Type and select the cities"}
          />
        </div>

        <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-y-3">
          {_.map(selectedLocations, (location, index) => {
            return (
              <div className="col-span-1" key={index + 1}>
                <TagComponent
                  text={_.get(location, "name")}
                  id={_.get(location, "id")}
                  onClose={closeSelectedTag}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="submit-button w-full md:w-1/3">
        <ButtonComponent
          padding="p-2"
          text="Continue"
          width="w-full"
          isPrimary={true}
          onClick={() => onClickContinue()}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span
            className="text-primaryColor font-semibold cursor-pointer ps-1"
            onClick={() => onClickLogin()}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
