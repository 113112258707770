import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import _ from "lodash";
import { IMAGE_URL } from "../../../../core/Constant";

export const SearchComponenet = ({
  value,
  onChange = () => {},
  onClick = () => {},
  searchResults = [],
  getSelectedSearchItem = () => {}
  
}) => {
  const searchRef = useRef(null);
  const [isResultEmpty, setIsResultsEmpty] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (searchResults.length > 0) {
      setIsResultsEmpty(false);
    } else {
      setIsResultsEmpty(true);
    }

    

    if(value){
      setShowDropdown(true);
    }else{
      setShowDropdown(false);
    }
  }, [searchResults]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      searchResults = [];
      setIsResultsEmpty(true);
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedSearchItem = (value) => {
    getSelectedSearchItem(_.get(value, 'title'));
    setShowDropdown(false);
  }

  return (
    <div class="relative" ref={searchRef}>
      <div class="absolute left-0 top-3 flex items-center ps-3 pointer-events-none" >
        <svg
          class="w-6 h-6 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className="custom-search focus:shadow-none w-full h-full p-4 ps-10 text-lg text-gray-900 border-none rounded-lg bg-white "
        placeholder="Search jobs here."
        value={value}
        onChange={onChange}
        required
      />
      <button
        type="submit"
        class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={onClick}
      >
        Search
      </button>
      <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      {showDropdown ? (
        <div
          className="absolute shadow-inner bg-white top-16 w-full p-3 max-h-[300px] overflow-y-auto
                      [&::-webkit-scrollbar]:w-2
                      [&::-webkit-scrollbar-track]:rounded-full
                      [&::-webkit-scrollbar-track]:bg-gray-100
                      [&::-webkit-scrollbar-thumb]:rounded-full
                      [&::-webkit-scrollbar-thumb]:bg-gray-300
                      dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                      dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
          style={{ zIndex: 1200 }}
        >

          {!isResultEmpty ? <div>
            {_.map(searchResults, (results, index) => {
            return (
              <div key={index + 1} onClick={()=> selectedSearchItem(results)}>
                <div className="hover:bg-blue-gray-50 p-2 rounded-lg m-0.5 flex h-full items-center">
                  <div className="">
                    <Avatar
                      src={IMAGE_URL + _.get(results, "job_image")}
                      alt="job-img"
                      className=""
                    />
                  </div>
                  <div className="1">
                    <span className="font-DM text-lg pe-2 ps-2">
                      {_.get(results, "title")}
                    </span>
                    <div>
                      <span className="font-DM text-sm pe-2 ps-2">
                        {_.get(results, "Company.name")}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
          </div>:  <div className="flex justify-center">
          <span className="text-center text-lg font-DM p-2">Sorry we did not find anything for your search</span>
          </div>}
         
        </div>
      ) : null}
    </div>
  );
};
