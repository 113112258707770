import React, { useEffect } from "react";
import _ from "lodash";
import { IMAGE_URL } from "../../../core/Constant";
import { getUserId } from "../../../core/UtilMethods";

export const MessageBubbleCardlet = ({
  text = "text",
  isPrimary = false,
  isDisabled = false,
  // isIcon=false,
  onClick = () => {},
  senderId = true,
  messageData = {},
}) => {
   const currentUserId = getUserId()
 //const currentUserId = 64;
//   let attachments;

  useEffect(() => {
  }, [messageData]);

  const onClickAttachmentDoc = (path) => {
    window.open(
        path, "_blank");
  }
  return currentUserId == _.get(messageData, "sender_id") ? (
    <div className="col-start-6 col-end-13 p-3 rounded-lg">
      <div className="flex items-center justify-start flex-row-reverse">
        <div className="relative mr-3 text-sm bg-primaryColor py-2 px-4 shadow rounded-xl">
            {/* {_.get(messageData, "attachment") !== null ? 
             <div>
             {_.map(JSON.parse(_.get(messageData, "attachment")), (item, index) => {
               return (
                 <div key={index + 1}>
                   {_.last(item.split(".")) == "pdf" ? (
                     <div className="bg-blue-800 rounded-lg border-2 border-blue-200 p-3 cursor-pointer" onClick={() => {onClickAttachmentDoc(IMAGE_URL + item)}}>
                       <p className="font-DM font-semibold text-white ">{_.last(item.split("/"))}</p>
                     </div>
                   ) : null}

                   {_.last(item.split(".")) == "png" || _.last(item.split(".")) == "jpg" ? 
                   (
                    <div>
                      <img src={IMAGE_URL + item} alt={'image'}  className="object-cover w-52 h-20"/>
                    </div>
                  ): null}
                 </div>
               );
             })}
           </div> : null}
       
          <span className="text-white font-DM font-medium text-[12px]">
            {_.get(messageData, "content")}
          </span> */}
             {_.get(messageData, "attachment") !== null ? 
             <div>
             {_.map(JSON.parse(_.get(messageData, "attachment")), (item, index) => {
               return (
                 <div key={index + 1}>
                   {_.last(item.split(".")) == "pdf" ? (
                     <div className="bg-blue-800 rounded-lg border-2 border-blue-200 p-3 cursor-pointer">
                       <p className="font-DM font-semibold text-white ">{_.last(item.split("/"))}</p>
                     </div>
                   ) : (
                     <div>
                       <img src={IMAGE_URL + item} alt={'image'}  className="object-cover w-53 h-52"/>
                     </div>
                   )}
                 </div>
               );
             })}
           </div> : null}

          <span className="text-white  font-medium  font-DM text-[12px]">
            {_.get(messageData, "content")}
          </span>
        </div>
        {/* <button class="flex items-center justify-center h-10 w-8 text-gray-400">
                        <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.60219 3.81842C1.89705 3.81842 2.18529 3.73428 2.43046 3.57664C2.67563 3.41901 2.86672 3.19495 2.97956 2.93282C3.0924 2.67068 3.12192 2.38223 3.0644 2.10394C3.00687 1.82566 2.86488 1.57004 2.65638 1.3694C2.44788 1.16877 2.18224 1.03214 1.89304 0.976785C1.60384 0.92143 1.30408 0.94984 1.03166 1.05842C0.759242 1.167 0.526401 1.35088 0.362584 1.5868C0.198766 1.82272 0.111329 2.10008 0.111329 2.38382C0.111329 2.7643 0.268401 3.12919 0.547991 3.39823C0.827581 3.66727 1.20679 3.81842 1.60219 3.81842ZM1.60219 10.9914C1.30732 10.9914 1.01908 11.0756 0.773911 11.2332C0.52874 11.3908 0.337653 11.6149 0.224814 11.877C0.111974 12.1392 0.0824501 12.4276 0.139975 12.7059C0.1975 12.9842 0.339491 13.2398 0.547991 13.4404C0.756492 13.6411 1.02214 13.7777 1.31133 13.833C1.60053 13.8884 1.90029 13.86 2.17271 13.7514C2.44513 13.6428 2.67797 13.459 2.84179 13.223C3.00561 12.9871 3.09304 12.7097 3.09304 12.426C3.09304 12.0455 2.93597 11.6806 2.65638 11.4116C2.37679 11.1426 1.99759 10.9914 1.60219 10.9914ZM1.60219 5.97032C1.30732 5.97032 1.01908 6.05445 0.773911 6.21209C0.52874 6.36973 0.337653 6.59378 0.224814 6.85592C0.111974 7.11806 0.0824501 7.40651 0.139975 7.68479C0.1975 7.96308 0.339491 8.2187 0.547991 8.41933C0.756492 8.61996 1.02214 8.75659 1.31133 8.81195C1.60053 8.8673 1.90029 8.83889 2.17271 8.73031C2.44513 8.62173 2.67797 8.43785 2.84179 8.20194C3.00561 7.96602 3.09304 7.68865 3.09304 7.40492C3.09304 7.02444 2.93597 6.65954 2.65638 6.3905C2.37679 6.12146 1.99759 5.97032 1.60219 5.97032Z" fill="#99A2BF" />
                        </svg>
                    </button> */}
      </div>
    </div>
  ) : (
    <div className="col-start-1 col-end-8 p-3 rounded-lg">
      <div className="flex flex-row items-center">
        <div className="relative ml-3 text-sm bg-darkGray py-2 px-4 shadow rounded-xl">
        {_.get(messageData, "attachment") !== null ? 
             <div>
             {_.map(JSON.parse(_.get(messageData, "attachment")), (item, index) => {
               return (
                 <div key={index + 1}>
                   {_.last(item.split(".")) == "pdf" ? (
                     <div className="bg-blue-800 rounded-lg border-2 border-blue-200 p-3 cursor-pointer">
                       <p className="font-DM font-semibold text-white ">{_.last(item.split("/"))}</p>
                     </div>
                   ) : (
                     <div>
                       <img src={IMAGE_URL + item} alt={'image'}  className="object-cover w-53 h-52"/>
                     </div>
                   )}
                 </div>
               );
             })}
           </div> : null}

          <span className="text-textGrey  font-medium  font-DM text-[12px]">
            {_.get(messageData, "content")}
          </span>
        </div>
        {/* <button class="flex items-center justify-center h-10 w-8 text-gray-400">
          <svg
            width="4"
            height="14"
            viewBox="0 0 4 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.60219 3.81842C1.89705 3.81842 2.18529 3.73428 2.43046 3.57664C2.67563 3.41901 2.86672 3.19495 2.97956 2.93282C3.0924 2.67068 3.12192 2.38223 3.0644 2.10394C3.00687 1.82566 2.86488 1.57004 2.65638 1.3694C2.44788 1.16877 2.18224 1.03214 1.89304 0.976785C1.60384 0.92143 1.30408 0.94984 1.03166 1.05842C0.759242 1.167 0.526401 1.35088 0.362584 1.5868C0.198766 1.82272 0.111329 2.10008 0.111329 2.38382C0.111329 2.7643 0.268401 3.12919 0.547991 3.39823C0.827581 3.66727 1.20679 3.81842 1.60219 3.81842ZM1.60219 10.9914C1.30732 10.9914 1.01908 11.0756 0.773911 11.2332C0.52874 11.3908 0.337653 11.6149 0.224814 11.877C0.111974 12.1392 0.0824501 12.4276 0.139975 12.7059C0.1975 12.9842 0.339491 13.2398 0.547991 13.4404C0.756492 13.6411 1.02214 13.7777 1.31133 13.833C1.60053 13.8884 1.90029 13.86 2.17271 13.7514C2.44513 13.6428 2.67797 13.459 2.84179 13.223C3.00561 12.9871 3.09304 12.7097 3.09304 12.426C3.09304 12.0455 2.93597 11.6806 2.65638 11.4116C2.37679 11.1426 1.99759 10.9914 1.60219 10.9914ZM1.60219 5.97032C1.30732 5.97032 1.01908 6.05445 0.773911 6.21209C0.52874 6.36973 0.337653 6.59378 0.224814 6.85592C0.111974 7.11806 0.0824501 7.40651 0.139975 7.68479C0.1975 7.96308 0.339491 8.2187 0.547991 8.41933C0.756492 8.61996 1.02214 8.75659 1.31133 8.81195C1.60053 8.8673 1.90029 8.83889 2.17271 8.73031C2.44513 8.62173 2.67797 8.43785 2.84179 8.20194C3.00561 7.96602 3.09304 7.68865 3.09304 7.40492C3.09304 7.02444 2.93597 6.65954 2.65638 6.3905C2.37679 6.12146 1.99759 5.97032 1.60219 5.97032Z"
              fill="#99A2BF"
            />
          </svg>
        </button> */}
      </div>
    </div>
  );
};
