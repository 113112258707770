import React from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets";
import { HeadingComponent } from "../../atoms";
import { AUTH_PROCESS_STEPS, USER_ROLE } from "../../../core/Constant";
import { getUserType, setUserType } from "../../../core/UtilMethods";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

export const CreateAccountForm = () => {
  const dispatch = useDispatch();
  const onClickButton = () => {
    setUserType(USER_ROLE.candidate);
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signUp }));
  };
  const onClickButtonRecruiter = () => {
    setUserType(USER_ROLE.recruiter);
    dispatch(
      Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signUpFormRecruiter })
    );
  };
  const handleClickLogin = () => {
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signIn }));
  };
  return (
    <div className="create-your-account-container">
      <div>
        <HeadingComponent
          headingText={"Create your account"}
          description={"Enter your email and password to sign in!"}
        />
      </div>
      <div className="process-select-section">
        <div
          className="process-select-btn-container"
          onClick={() => {
            onClickButton();
          }}
        >
          <div className="process-select-upper-section">
            <div>
              <h2 className="primary-heading-md">Continue as a Candidate</h2>
            </div>
            <div>
              <img src={IMAGES.ONBORADING.ICON_ONBOARDING_ARROW} alt="logo" />
            </div>
          </div>
          <div className="w-auto">
            <p className="small-text-description">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga,
              aperiam! Consequatur, recusandae.
            </p>
          </div>
        </div>

        <div
          className="process-select-btn-container"
          onClick={() => {
            onClickButtonRecruiter();
          }}
        >
          <div className="process-select-upper-section">
            <div>
              <h2 className="primary-heading-md">Continue as a Recruiter</h2>
            </div>
            <div>
              <img src={IMAGES.ONBORADING.ICON_ONBOARDING_ARROW} alt="logo" />
            </div>
          </div>
          <div className="w-auto">
            <p className="small-text-description">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga,
              aperiam! Consequatur, recusandae.
            </p>
          </div>
        </div>
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an Account?
          <span
            className="text-primaryColor font-semibold cursor-pointer ps-1"
            onClick={handleClickLogin}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
