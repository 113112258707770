import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets";
import { AUTH_PROCESS_STEPS, EMAIL_REGEX, getText, MINIMUM_PASSWORD_CHARACTERS, onToastNotify } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import {
  ButtonComponent,
  HeadingComponent,
  TextFieldComponent
} from "../../atoms";
import { PasswordField } from "../../atoms/Inputs";

export const SignUpFormCandidate = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visibilty, setVisibility] = useState(false);
  const [visibiltycnf, setVisibilityCnf] = useState(false);
  const userdetails = useSelector(state => state.auth.get('userDetails'));



  useEffect(()=>{
    setEmail(userdetails?.email)
  },[])

  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
   // setIsEmailValid(isValid);
    setEmailError(isValid ? false : true);
  };


  const onPasswordChange = (e) => {
    const text = getText(e);
    let isValid = false;
    isValid = text.trim().length > MINIMUM_PASSWORD_CHARACTERS;
    setPassword(text);
    setIsPasswordValid(isValid);
    setPasswordError(isValid ? false : true);
  };

  const onClickEye = (value) => {
    setVisibility(value ? false : true);
  };

  const onClickEyeCnf= (value) => {
    setVisibilityCnf(value ? false : true);
  };



  const onConfirmPassword = (e) => {
    const text  = getText(e)  
    setConfirmPassword(text)
    if(text !== password){
       setConfirmPasswordError(true)
    }
    else{
      setConfirmPasswordError(false)
    }
     
  }

  const onClickCreateAccount = () => {
    if(password == confirmPassword){
      dispatch(Actions.auth.storeUserDetails({ password,confirmPassword }));
      dispatch(Actions.auth.authStep({ step:AUTH_PROCESS_STEPS.onboarding_pick_location_can }))
      navigate("/onboarding-process-candidate");
    }
    else{
      onToastNotify('Confirm Password Incorrect')
    }
  };
  const onClickBack = () => {
    dispatch(Actions.auth.authStep({ step:AUTH_PROCESS_STEPS.signUp }))
  }

  const onClickLogin = () => {
    dispatch(Actions.auth.authStep({ step:AUTH_PROCESS_STEPS.signIn }))
  }
  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <button onClick={onClickBack}>
          <div className="button-content flex gap-x-2 items-center">
            <img src={IMAGES.COMMON.LEFT_ARRROW} alt="logo" />
            <p className="font-DM">Back</p>
          </div>
        </button>
      </div>
      <HeadingComponent
        headingText={"Sign up as a Candidate"}
        description={"In publishing and graphic design, Lorem ipsum"}
      />

      <div className="sign-in-content-wrapper">
        <TextFieldComponent
          type="text"
          label="Email"
          placeholder="Enter your email address"
          value={email}
          onChange={onEmailChange}
          error={emailError}
          errortxt="Please enter a valid email"
        />

        <PasswordField
           onChange={onPasswordChange}
             label="Password"
           placeholder="Enter your Password here"
           type={visibilty ? "text" : "password"}
           value={password}
           isVisible={visibilty}
           error={passwordError}
           errortxt="Password is incorrect"
           onclickEyeBtn={() => onClickEye(visibilty)}
        />

        <PasswordField
          label="Confirm Password"
          placeholder="Re type enter your password"
          value={confirmPassword}
          type={visibiltycnf ? "text" : "password"}
          onChange={onConfirmPassword}
          isVisible={visibiltycnf}
          error={confirmPasswordError}
          errortxt="Please enter a  Correct Password"
          onclickEyeBtn={() => onClickEyeCnf(visibiltycnf)}
        />
      </div>

      <div className="submit-button">
        <ButtonComponent
          text="Create account "
          width="w-full"
          isPrimary={true}
          onClick={() => onClickCreateAccount()}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span
            className="text-primaryColor font-semibold cursor-pointer"
            onClick={onClickLogin}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
