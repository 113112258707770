import React, { useEffect, useState } from "react";
import { ButtonComponent } from "../../atoms";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { dateFromNow } from "../../../core/Constant";
import { InterviewMoreDetailsModal, JobViewModal } from "../Modals";

export const InterviewCandidateCard = ({
  text = "label",
  onClose,
  close = false,
  isrecruiter = false,
  interview = {},
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {}, [interview]);

  return (
    <div className="">
      <div className="bg-bgColor rounded-[12px] p-[15px] my-1">
        <div className="grid grid-cols-5 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-5  sm:grid-cols-5 gap-4">
          <div className="col-span-1 xl:col-span-10  sm:col-span-4 md:col-span-4">
            <div className="flex flex-row items-center mb-4">
              <span className="text-lableColor font-DM text-[24px] font-[700]">
                {_.get(interview, "job.title")}
              </span>
              <span
                className={
                  isrecruiter
                    ? "text-darktextColor font-DM text-[16px] font-[500] ml-4 bg-lighterGreen py-1 px-4 rounded-lg"
                    : "text-primaryColor font-DM text-[18px] font-[500] ml-4"
                }
              >
                {dateFromNow(_.get(interview, "created_at"))}
              </span>
            </div>
            <div className="flex flex-row items-center mb-2">
              <span className="text-ash font-DM text-[18px] font-[500]">
                {_.get(interview, "job.location")}
              </span>
              <span className="text-lighterGreen font-DM text-[18px] font-[500] ml-4">
                {_.get(interview, "job.job_type")}
              </span>
            </div>
            <span className="text-lableColor font-DM text-[16px] font-[400]">
              {_.get(interview, "job.description")}
            </span>
          </div>
          <div className="col-span-2 xl:col-span-2 lg:col-span-2 ">
            <div className="grid grid-flow-row-dense grid-cols-1 gap-2">
              <div className="col-auto">
                <ButtonComponent text="View More"  onClick={() =>setOpen(true)}/>
              </div>
              {/* <div className="col-auto">
                <ButtonComponent text="Send Message" isPrimary={false} />
              </div> */}
            </div>
          </div>
        </div>
        {isrecruiter ? (
          <div className="my-8 w-full">
            <div className="bg-white p-5 w-full rounded-2xl">
              <span className="text-lableColor font-DM text-[14px] font-normal">
                Find candidates who are experienced in software engineering,
                specifically for software engineer, developer, or programmer
                roles, and have skills in Java or Python. The candidates should
                be located in Calgary.
              </span>
            </div>
            {/* <div className="grid grid-cols-1 lg:grid-cols-12 mt-8 items-center">
          <div className="col-span-1 lg:col-span-8 grid grid-flow-row-dense grid-cols-2 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-1">
            <TagComponent plus={true} />
            <TagComponent plus={true} />
            <TagComponent plus={true} />
            <TagComponent plus={true} />
          </div>
          <div className="col-span-1 lg:col-span-4 flex flex-col mt-4 items-end">
            <div className="flex flex-row items-center justify-end">
              <TagComponent />
            </div>
            <div className="grid grid-flow-row-dense grid-cols-2 gap-2 mt-3 w-full 2xl:w-fit xl:w-fit lg:w-full md:w-fit ">
              <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-1">
                <ButtonComponent
                  isPrimary={false}
                  padding={"p-2"}
                  fontsize="text-sm"
                  text="Back"
                />
              </div>
              <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-1">
                <ButtonComponent
                  padding={"p-2"}
                  fontsize="text-sm"
                  text="Search candidates"
                />
              </div>
            </div>
          </div>
        </div> */}
          </div>
        ) : null}
      </div>
      <InterviewMoreDetailsModal open={open} onClose={()=> setOpen(false)} details={interview}/>
      {/* <JobViewModal open={open} details={item} onClose={() => setOpen(false)} /> */}
    </div>
  );
};
