import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  DropdownComponentCandidate,
  StepperComponent,
  TagComponent,
} from "../../atoms";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { TypeAheadSearch } from "../../atoms/Inputs/TypeAhead/TypeAheadSearch";

export const CandidateSkillsAndInterest = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const seniotrity = [
    { option: "Entry" },
    { option: "Jounior" },
    { option: "Mid" },
    { option: "Senior" },
    { option: "Lead" },
  ];
  const [selectedSeniority, setSelectedSeniority] = useState("");
  const currentStatus = [
    { option: "Open to opportunities", value: "Open to opportunities" },
    { option: "Just casually looking", value: "Just Casually looking" },
  ];
  const [selectedStatus, setSelectedStatus] = useState('Open to opportunities');
  const [selectedJobRole, setSelectedJobRole] = useState([]);
  const [selectedCoreSkills, setSelectedCoreSkills] = useState([]);
  const [selectedInterestedSkills, setSelectedInterestedSkills] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [error, setError] = useState(false);
  const workType = [
    { option: "On-site" },
    { option: "Hybrid" },
    { option: "Remote" },
  ];
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const dispatch = useDispatch();
  const jobRoles = useSelector((state) => state.common.get("jobRoleList"));
  const candiadteStore = useSelector((state) =>
    state.auth.get("userDetails")
  );

  const coreSkills = useSelector((state) => state.common.get("skillData"));
  const industryList = useSelector((state) => state.common.get("industryList"));

  useEffect(() => {
    dispatch(Actions.common.getjobRoleList());
    dispatch(Actions.common.getskillList());
    dispatch(Actions.common.getindustryList());
  }, [dispatch]);

  useEffect(() => {
    console.log("jobRoles: ", jobRoles);
    console.log("candiadteStore: ", candiadteStore);
    console.log("industryList: ", industryList);
    console.log("coreSkills: ", coreSkills);
  }, [jobRoles, candiadteStore, industryList, coreSkills]);

  const selectedItem = (item) => {
    setSelectedJobRole([...selectedJobRole, item]);
    setError(false);
  };

  const getselectedIndustry = (item) => {
    setSelectedIndustry([...selectedIndustry, item]);
    setError(false);
  };

  const closeSelectedIndustryTag = (id) => {
    const arr = _.filter(selectedIndustry, (item) => _.get(item, "id") !== id);
    setSelectedIndustry(arr);
  };
  const getselectedSkills = (item) => {
    setSelectedCoreSkills([...selectedCoreSkills, item]);
    setError(false);
  };
  const closeSelectedSkillsTag = (id) => {
    const arr = _.filter(selectedCoreSkills, (item) => _.get(item, "id") !== id);
    setSelectedCoreSkills(arr);
  };

  const getselectedInterestedSkills = (item) => {
    setSelectedInterestedSkills([...selectedInterestedSkills, item]);
    setError(false);
  };
  const closeSelectedInterestedSkillTag = (id) => {
    const arr = _.filter(selectedInterestedSkills, (item) => _.get(item, "id") !== id);
    setSelectedInterestedSkills(arr);
  };
  const closeSelectedTag = (id) => {
    const arr = _.filter(selectedJobRole, (item) => _.get(item, "id") !== id);
    setSelectedJobRole(arr);
  };
  const getSelectedStatus = (value) => {
    setSelectedStatus(_.get(value,'value'));
  };
  const getSeniorLevel = (value) => {
    setSelectedSeniority(_.get(value, "option"));
  };
  const getWorkType = (value) => {
    setSelectedWorkType(_.get(value, "option"));
  };
  const onClickContinue = () => {
    setCurrentStep(2);
  };

  const onClickFinish = () => {
    console.log(selectedCoreSkills,'  ', selectedInterestedSkills, '', selectedIndustry)
    if(selectedStatus && selectedSeniority ){
      dispatch(
        Actions.auth.signUpCompleteCandidate({
          current_status: selectedStatus, //  required | value: 'Open to opportunities'
          interested_job_roles: selectedJobRole,
          seniority_level: selectedSeniority, // values: 'Entry','Junior','Mid','Senior','Lead'
          work_type: selectedWorkType, // values: 'On-site','Remote','Hybrid'
          core_skills: selectedCoreSkills,
          interested_skills: selectedInterestedSkills,
          industries: selectedIndustry
        })
      );
    }
   
  };
  return (
    <div className="flex justify-center flex-col items-center h-full min-h-svh bg-[#EDF6FE] pt-10 pb-10">
      <div className="flex flex-col items-center w-1/3">
        <div className="flex gap-x-2 w-full">
          {Array.from({ length: 2 })?.map((_, index) => {
            return (
              <div
                className={
                  currentStep >= index + 1
                    ? "stepper-block-complete w-full"
                    : "stepper-block w-full"
                }
                key={index + 1}
              ></div>
            );
          })}
        </div>
        <span className="align-middle text-center font-DM text-gray-400 mt-1">
          {currentStep} of 2
        </span>
      </div>

      {currentStep == 1 ? (
        <>
          <div className="bg-white flex flex-col gap-10 h-fit w-2/3 p-8 rounded-2xl">
            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                What is your current status?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Your profile will display your current status, which will be
                used to customize your experience
              </p>
              <div className="w-2/4 mt-3">
                <DropdownComponentCandidate
                  defaultOption={selectedStatus}
                  dropdownOptions={currentStatus}
                  getSelectedOption={getSelectedStatus}
                />
              </div>
            </div>

            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                What roles are you looking for?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Your profile will display your current status, which will be
                used to customize your experience
              </p>
              <div className="w-2/4 mt-3">
                <div className="w-full">
                  <div className="mb-4">
                    <TypeAheadSearch
                      error={error}
                      errortext="Please select a location"
                      listData={jobRoles}
                      selectedItem={selectedItem}
                      placeholder={"Type and select the cities"}
                      showOnlyName={true}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-2">
                {_.map(selectedJobRole, (location, index) => {
                  return (
                    <div className="col-span-1" key={index + 1}>
                      <TagComponent
                        text={_.get(location, "name")}
                        id={_.get(location, "id")}
                        onClose={closeSelectedTag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                What is your seniority level?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Select your seniority level. Select up to 3 levels
              </p>
              <div className="grid grid-flow-row-dense grid-cols-5 gap-2 mt-2">
                {_.map(seniotrity, (item, index) => {
                  return (
                    <div className="col-span-1" key={index + 1}>
                      <button
                        onClick={() => {
                          getSeniorLevel(item);
                        }}
                        className={`liteGray2Btn ${
                          _.get(item, "option") == selectedSeniority
                            ? "border-gray-700"
                            : null
                        }`}
                      >
                        {_.get(item, "option")}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-center w-full">
              <div className="w-1/4">
                <ButtonComponent text="Continue" onClick={onClickContinue} />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {currentStep == 2 ? (
        <>
          <div className="bg-white flex flex-col gap-10 h-fit w-2/3 p-8 rounded-2xl">
            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                Skills and interests
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Select your skills and interests.
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                What types of work are looking for?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Select your seniority level. Select up to 3 levels
              </p>
              <div className="grid grid-flow-row-dense grid-cols-3 gap-2 mt-2">
                {_.map(workType, (item, index) => {
                  return (
                    <div
                      className="col-span-1 flex justify-center"
                      key={index + 1}
                    >
                      <button
                        onClick={() => {
                          getWorkType(item);
                        }}
                        className={`liteGray2Btn ${
                          _.get(item, "option") == selectedWorkType
                            ? "border-gray-700"
                            : null
                        }`}
                      >
                        {_.get(item, "option")}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                What are your core skills?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Select your seniority level. Select up to 3 levels
              </p>
              <div className="w-2/4 mt-3">
                <div className="w-full">
                  <div className="mb-4">
                    <TypeAheadSearch
                      error={error}
                      errortext="Please select core skills"
                      listData={coreSkills}
                      selectedItem={getselectedSkills}
                      placeholder={"Type a skill"}
                      showOnlyName={true}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-2">
                {_.map(selectedCoreSkills, (location, index) => {
                  return (
                    <div className="col-span-1" key={index + 1}>
                      <TagComponent
                        text={_.get(location, "name")}
                        id={_.get(location, "id")}
                        onClose={closeSelectedSkillsTag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                What are your most interested skills to use?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Select your seniority level. Select up to 3 levels
              </p>
              <div className="w-2/4 mt-3">
                <div className="w-full">
                  <div className="mb-4">
                    <TypeAheadSearch
                      error={error}
                      errortext="Please select interested skill"
                      listData={coreSkills}
                      selectedItem={getselectedInterestedSkills}
                      placeholder={"Type and select interested skills"}
                      showOnlyName={true}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-2">
                {_.map(selectedInterestedSkills, (location, index) => {
                  return (
                    <div className="col-span-1" key={index + 1}>
                      <TagComponent
                        text={_.get(location, "name")}
                        id={_.get(location, "id")}
                        onClose={closeSelectedInterestedSkillTag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <p className="font-DM font-bold text-3xl text-center text-darkBlue ">
                Which industries are you most interested in?
              </p>
              <p className="text-center text-gray-400 font-DM ">
                Select your seniority level. Select up to 3 levels
              </p>
              <div className="w-2/4 mt-3">
                <div className="w-full">
                  <div className="mb-4">
                    <TypeAheadSearch
                      error={error}
                      errortext="Please select interested industry"
                      listData={industryList}
                      selectedItem={getselectedIndustry}
                      placeholder={"Type and select interested industry"}
                      showOnlyName={true}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-2">
                {_.map(selectedIndustry, (location, index) => {
                  return (
                    <div className="col-span-1" key={index + 1}>
                      <TagComponent
                        text={_.get(location, "name")}
                        id={_.get(location, "id")}
                        onClose={closeSelectedIndustryTag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-center w-full">
              <div className="w-1/4">
                <ButtonComponent text="Finish" onClick={onClickFinish} />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
