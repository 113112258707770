import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
  return `jobpost/${path}`;
};
class JobRepository extends Repository {
  saveJob = async (jobId) => {
    try {
      const data = await this.postData(getPath(`savejob/${jobId}`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  removeSavedJob = async (noteId) => {
    try {
      const data = await this.deleteData(getPath(`removesavedjob/${noteId}`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
  getAllJobsCandidate = async () => {
    try {
      const data = await this.getData(getPath(`getjobsforcandidate`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getAJobPost = async (jobId) => {
    try {
      const data = await this.getData(
        getPath(`getjobpostbyid/${jobId}`),
        {},
        ""
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  applyJob = async (jobId, params) => {
    try {
      const data = await this.postData(getPath(`applyforjob/${jobId}`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getAllApplyedJobsCandidate = async (params) => {
    try {
      const data = await this.postData(getPath(`getallappliedjobs`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getAllSavedJobsCandidate = async (params) => {
    try {
      const data = await this.postData(getPath(`getallsavedjobs`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getJobRoles = async (params) => {
    try {
      const data = await this.getData(
        getPath(`filterinterestedjobroles`),
        params
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getAllJobTitles = async (params) => {
    try {
      const data = await this.getData(getPath(`getalljobtitlelist`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getAllJobLocationList = async (params) => {
    try {
      const data = await this.getData(getPath(`getalljoblocationlist`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };


  searchJobs = async (params) => {
    try {
      const data = await this.postData(getPath(`searchJobs`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getAllJobRecruiter = async (params) => {
    try {
      const data = await this.getData(getPath(`getjobpostbyrecruiter`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
  setRecruiterjobStatus= async (jobID,params) => {
    console.log(jobID,params,'paramssss')
    try {
      const data = await this.putData(getPath(`changestatus/${jobID}`), {status:params});
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  editJobpost= async (jobID,params) => {
    console.log(jobID,params,'paramssss')
    try {
      const data = await this.postData(getPath(`editjobpost`), params);
      console.log(data,'dataaaaaaa')
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  jobOffers = async () => {
    try {
      const data = await this.getData(getPath(`getjoboffersforcandidate`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  suggestedJobs = async () => {
    try {
      const data = await this.getData(getPath(`getjoboffersforcandidate`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

}
export default new JobRepository("job");
