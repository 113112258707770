import React, { useEffect, useRef, useState } from "react";
import {
  DropdownComponent,
  DropdownComponentCandidate,
  TabButtonComponent,
} from "../../../components/atoms";
import { JobCard } from "../../../components";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import _ from "lodash";

const MyJobScreen = ({
  getAllSavedJobCandidate,
  getAllAppliedJobCandidate,
  getAllLocations,

  savedJobsCandidate,
  appliedJobsCandidate,
  allLocations,
}) => {
  const [appliedJobScreen, setAppliedJobScreens] = useState(true);
  const sortByOptions = [
    { option: "Newest", value: "newest" },
    { option: "Oldest", value: "oldest" },
    { option: "A-Z", value: "title_asc" },
    { option: "Z-A", value: "title_desc" },
  ];

  const workTypeOptions = [
    { option: "On-Site", value: "on-site" },
    { option: "Hybrid", value: "hybrid" },
    { option: "Remote", value: "remote" },
  ];
  // const [selectedSort, setSelectedSort] = useState("");
  // const [selectedWorkType, setSelectedWorkType] = useState("");
  // const [selectedLocation, setSelectedLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const selectedSort = useRef('');
  const selectedWorkType = useRef('');
  const selectedLocation = useRef('');

  useEffect(() => {
    getAllSavedJobCandidate();
    getAllAppliedJobCandidate();
    getAllLocations();
  }, []);

  useEffect(() => {
    const arr = _.map(allLocations, (item) => {
      return {
        option: `${_.get(item, "name")} ${_.get(item, "country_name")}`,
        value: _.get(item, "id"),
      };
    });
    setLocations(arr);
  }, [savedJobsCandidate, appliedJobsCandidate, allLocations]);

  const onClickTab = () => {
    setAppliedJobScreens(appliedJobScreen ? false : true);
  };

  const getSelectedSortFilter = (sort) => {
    selectedSort.current = sort;
    applyFilter();
  };

  const getSelecetdWorktype = (workType) => {
    selectedWorkType.current = workType;
    applyFilter();
  };

  const getSelecetdLocation = (location) => {
    selectedLocation.current = location
    applyFilter();
  };


  const applyFilter = () => {
    if (appliedJobScreen) {
      getAllAppliedJobCandidate({
        work_type: _.get(selectedWorkType, "current.value"), //  not required | value: user selected work type name
        location: _.get(selectedLocation, "current.value"), //  not required | value: user selected location name
        sortBy: _.get(selectedSort, "current.value"), // not required | default empty string takes the newest | values: newest , oldest, title_asc, title_desc
      });

      // selectedLocation?.current = '';
      // selectedSort?.current = '';
      // selectedWorkType?.current = '';
    } else {
      getAllSavedJobCandidate({
        work_type: _.get(selectedWorkType, "current.value"), //  not required | value: user selected work type name
        location: _.get(selectedLocation, "current.value"), //  not required | value: user selected location name
        sortBy: _.get(selectedSort, "current.value"), // not required | default empty string takes the newest | values: newest , oldest, title_asc, title_desc
      });
      // selectedLocation?.current = '';
      // selectedSort?.current = '';
      // selectedWorkType?.current = '';
    }
  };
  return (
    <div className="job-view-container">
      <div className="view-job-menu-container">
        <div className="top-section-menu">
          <div className="top-heading-section">
            <div className="heading-jobview">
              {appliedJobScreen ? (
                <p className="text-2xl font-DM text-darkBlue font-semibold">
                  Applied Jobs
                </p>
              ) : (
                <p className="text-2xl font-DM text-darkBlue font-semibold">
                  Saved Jobs
                </p>
              )}

              <p className="text-sm font-DM text-[#99A2BF] font-normal">
                In publishing and graphic design, Lorem ipsum is a placeholder
              </p>
            </div>
          </div>
          <div className="top-tab-button-wrap">
            <TabButtonComponent
              onClick={() => onClickTab()}
              isApplied={appliedJobScreen}
              showThirdTab={false}
            />
          </div>
        </div>
        <div className="bottom-section-menu">
          <div className="bottom-section-left-wrapper">
            <div className="bottom-left-inner-wrapper">
              <div className="bottom-dropdown-item">
                <DropdownComponentCandidate
                  defaultOption={_.get(selectedWorkType, "current.option")}
                  dropdownOptions={workTypeOptions}
                  getSelectedOption={getSelecetdWorktype}
                />
              </div>
              <div className="bottom-dropdown-item">
              <DropdownComponentCandidate
                dropdownOptions={sortByOptions}
                defaultOption={_.get(selectedSort, "current.option")}
                getSelectedOption={getSelectedSortFilter}
              />
                {/* <DropdownComponentCandidate
                  defaultOption={_.get(selectedLocation, "current.option")}
                  dropdownOptions={locations}
                  getSelectedOption={getSelecetdLocation}
                /> */}
              </div>
              {/* <div className="bottom-dropdown-item">
                <DropdownComponentCandidate defaultOption="Work Experiance" />
              </div>
              <div className="bottom-dropdown-item">
                <DropdownComponentCandidate defaultOption="Salary Range" />
              </div> */}
            </div>
          </div>
          {/* <div className="bottom-section-right-wrapper">
            <div className="bottom-right-inner-wrapper">
              <DropdownComponentCandidate
                dropdownOptions={sortByOptions}
                defaultOption={_.get(selectedSort, "current.option")}
                getSelectedOption={getSelectedSortFilter}
              />
            </div>
          </div> */}
        </div>
      </div>

      {appliedJobScreen ? (
        <div className="grid grid-flow-row-dense 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2  mt-4 gap-2">
          {_.map(appliedJobsCandidate, (job, index) => {
            return (
              <div key={index + 1}>
                <JobCard item={job} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="grid grid-flow-row-dense 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2  mt-4 gap-2">
          {_.map(savedJobsCandidate, (job, index) => {
            return (
              <div key={index + 1}>
                <JobCard item={job} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    appliedJobsCandidate: state.jobs.get("appliedJobsCandidate"),
    savedJobsCandidate: state.jobs.get("savedJobsCandidate"),
    allLocations: state.onboardingProcess.get("locationList"),
  }),
  {
    getAllAppliedJobCandidate: Actions.jobs.getAllAppliedJobCandidate,
    getAllSavedJobCandidate: Actions.jobs.getAllSavedJobCandidate,
    getAllLocations: Actions.onboardingProcess.getAllLocations,
  }
)(MyJobScreen);
