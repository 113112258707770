import React from "react";
import { ModuleEvents } from "./Actions";
import { IMAGE_URL, onToast } from "../../core/Constant";
import { Actions } from "../../core/modules/Actions";
import _ from "lodash";
// import {navigate} from '../../core/repository/Repository';

export default {
  [ModuleEvents.NETWORK_ERROR]: ({ dispatch, payload, appState }) => {
    // Logger.infor(JSON.stringify(payload))
  },

  [ModuleEvents.UPDATE_CANDIDATE]: ({ dispatch, payload, appState }) => {
    onToast("Profile Update", payload, false);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.profile.getCandiateDetails());
    }
  },

  [ModuleEvents.INVITE_FRIENDS]: ({ dispatch, payload, appState }) => {
    onToast("Invite Friends", payload, false);
    // if (payload && payload.status_code == 1) {
    //   dispatch(Actions.profile.getCandiateDetails());
    // }
  },

  [ModuleEvents.CANDIDATE_PW_CHANGE]: ({ dispatch, payload, appState }) => {
    onToast("Password Change", payload, false);
    // if (payload && payload.status_code == 1) {
    //   dispatch(Actions.profile.getCandiateDetails());
    // }
  },

  [ModuleEvents.UPLOAD_VIDEO]: ({ dispatch, payload, appState }) => {
    onToast("Upload Video", payload, false);
    if (payload && payload.status_code == 1) {
      // dispatch(Actions.profile.getCandiateDetails());
    }
  },

  [ModuleEvents.UPDATE_CANDIDATE_CV]: ({ dispatch, payload, appState }) => {
    onToast("CV Updated", payload, false);
    if (payload && payload.status_code == 1) {
      // dispatch(Actions.profile.getCandiateDetails());
    }
  },

  [ModuleEvents.RECRUITER_EDIT]: ({ dispatch, payload, appState }) => {
    onToast("Profile Edit", payload, false);
    if (payload && payload.status_code == 1) {
      // dispatch(Actions.profile.getCandiateDetails());
    }
  },

  [ModuleEvents.UPDATE_CANDIDATE_PROFILE_PIC]: ({
    dispatch,
    payload,
    appState,
  }) => {
    console.log("pay: ", payload);
    onToast("Profile Picture", payload, false);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.profile.getCandiateDetails());
    }
  },
  [ModuleEvents.UPDATE_CANDIDATE_PROFILE_PIC]: ({
    dispatch,
    payload,
    appState,
  }) => {
    onToast("Profile", payload, false);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.profile.getCompanyDetails());
    }
  },

  [ModuleEvents.GET_CANDIDATE_DETAILS]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 1) {
      dispatch(
        Actions.auth.storeProfilePic({
          path: IMAGE_URL + _.get(payload, "data.profile_image"),
        })
      );
    }
  },
};
