import { createAction } from "../../core/AppUtils";
import AuthRepository from "../../core/repository/AuthRepository";
import CommonRepository from "../../core/repository/CommonRepository";
import DashboardRepository from "../../core/repository/DashboardRepository";
import InterviewRepository from "../../core/repository/InterviewRepository";
import MessageRepository from "../../core/repository/MessageRepository";
import NotesRepository from "../../core/repository/NotesRepository";
import ProfileViewRepository from "../../core/repository/ProfileViewRepository";
import ScreeningQuestionsRepository from "../../core/repository/ScreeningQuestions";
import HomeRepository from "../../core/repository/HomeRepository";

export const ModuleEvents = {
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_FINISHED: "LOADING_FINISHED",
  NETWORK_ERROR: "NETWORK_ERROR",
  GET_COURSE_UPSKILL: "GET_COURSE_UPSKILL",
  GET_CANDIDATE_VACANCIES: "GET_CANDIDATE_VACANCIES",
  GET_UPSKILL_COURSES: "GET_UPSKILL_COURSES",
  GET_CAREER_ADVICE: "GET_CAREER_ADVICE",
  GET_TIPS_N_HINTS: "GET_TIPS_N_HINTS",
  GET_NOTES: "GET_NOTES",
  GET_DASHBOARD_CARDS: "GET_DASHBIARD_CARDS",
  GET_RECRUITER_DASHBOARD: "GET_RECRUITER_DASHBOARD",
  CREATE_JOB_POST: "CREATE_JOB_POST",
  GET_DASHBOARD_DETAILS: "GET_DASHBOARD_DETAILS",
  GET_ALL_NOTES: "GET_ALL_NOTES",
  ADD_NOTE: "ADD_NOTE",
  GET_NOTE: "GET_NOTE",
  UPDATE_NOTE: "UPDATE_NOTE",
  REMOVE_NOTE: "REMOVE_NOTE",
  GET_CHAT_LIST: "GET_CHAT_LIST",
  GET_MESSAGES: "GET_MESSAGES",
  CREATE_CHAT: "CREATE_CHAT",
  CREATE_MESSAGE_FOR_CHAT: "CREATE_MESSAGE_FOR_CHAT",
  GET_PROFILE_VIEWS: "GET_PROFILE_VIEWS",
  GET_ALL_NOTIFICATIONS: "GET_ALL_NOTIFICATIONS",
  GET_UNREAD_NOTIFICATIONS: "GET_UNREAD_NOTIFICATIONS",
  GET_READ_NOTIFICATIONS: "GET_READ_NOTIFICATIONS",
  MARK_ALL_NOTIICATIONS: "MARK_ALL_NOTIICATIONS",
  MARK_NOTIICATION: "MARK_NOTIICATION",
  GET_INTERVIEWS_CANDIDATE: "GET_INTERVIEWS_CANDIDATE",
  GET_INTERVIEW_DETAILS_CANDIDATE: "GET_INTERVIEW_DETAILS_CANDIDATE",
  GET_JOB_OFFERS_CANDIDATE: "GET_JOB_OFFERS_CANDIDATE",
  GET_SCREEING_QUESTIONS: "GET_SCREEING_QUESTIONS",
  GET_SCREEING_QUESTIONS_LIST: "GET_SCREEING_QUESTIONS_LIST",
  ANSWER_SCREENING_QUESTIONS: "ANSWER_SCREENING_QUESTIONS",
  JOB_ROLE_LIST: "JOB_ROLE_LIST",
  SKILLS_LIST: "SKILLS_LIST",
  SEACRH_CANDIDIATES: "SEACRH_CANDIDIATES",
  CREATE_MCQ_QUESTIONS: "CREATE_MCQ_QUESTIONS",
  CREATE_VIDEO_QUESTIONS: "CREATE_VIDEO_QUESTIONS",
  GET_SCREENING_QUESTIONS: "GET_SCREENING_QUESTIONS",
  DELETE_SCREENING_QUESTIONS: "DELETE_SCREENING_QUESTIONS",
  GET_APLLIED_CANDIDATES: "GET_APLLIED_CANDIDATES",
  GET_REJECTED_CANDIDIATES: "GET_REJECTED_CANDIDIATES",
  GET_QUALIFIED_CANDIDIATES: "GET_QUALIFIED_CANDIDIATES",
  GET_INTERVIEW_LIST: "GET_INTERVIEW_LIST",
  GET_INTERVIEW_DETAILS: "GET_INTERVIEW_DETAILS",
  MARK_AS_QUALIFIED: "MARK_AS_QUALIFIED",
  MARK_AS_REJECTED: "MARK_AS_REJECTED",
  ADD_INTERVIEW: "ADD_INTERVIEW",
  UPDATE_INTERVIEW: "UPDATE_INTERVIEW",
  UPDATE_QUESTIONS: "UPDATE_QUESTIONS",
  INITIATE_SCREENING:'INITIATE_SCREENING',
  OFFER_JOB:'OFFER_JOB',
  GET_OFFERED_CANDIDATES:'GET_OFFERED_CANDIDATES',
  GET_OFFERREJECTED_CANDIDIATES:'GET_OFFERREJECTED_CANDIDIATES',
  GET_OFFERACCEPTED_CANDIDIATES:'GET_OFFERACCEPTED_CANDIDIATES',
  CREATE_CONVERSATION:'CREATE_CONVERSATION',
  GET_CONVERSATION_LIST:'GET_CONVERSATION_LIST',
  GET_MESSAGES_RECRUIT:'GET_MESSAGES_RECRUIT',
  CREATE_MESSAGE:'CREATE_MESSAGE',
  STORE_CREATE_JOB:'STORE_CREATE_JOB',
  GET_ANSWERS_RECRUITER:'GET_ANSWERS_RECRUITER',
  GET_SINGLE_CANDIDITE:'GET_SINGLE_CANDIDITE',
  RESET_CREATE_JOB:'RESET_CREATE_JOB'

};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, (error) => error),
  loadingStarted: createAction(
    ModuleEvents.LOADING_STARTED,
    (action) => action
  ),
  loadingFinished: createAction(
    ModuleEvents.LOADING_FINISHED,
    (action) => action
  ),

  getDashboardDetails: createAction(
    ModuleEvents.GET_DASHBOARD_DETAILS,
    async () => {
      return await DashboardRepository.candidateDashboard();
    }
  ),

  getAllNotes: createAction(ModuleEvents.GET_ALL_NOTES, async () => {
    return await NotesRepository.getAllNotes();
  }),

  addNote: createAction(ModuleEvents.ADD_NOTE, async (params) => {
    return await NotesRepository.addNote(params);
  }),

  getNote: createAction(ModuleEvents.GET_NOTE, async (param) => {
    return await NotesRepository.getNote(param);
  }),

  updateNote: createAction(ModuleEvents.UPDATE_NOTE, async (noteId, params) => {
    return await NotesRepository.updateNote(noteId, params);
  }),

  removeNote: createAction(ModuleEvents.REMOVE_NOTE, async (param) => {
    return await NotesRepository.removeNote(param);
  }),

  createChat: createAction(ModuleEvents.CREATE_CHAT, async (param) => {
    return await MessageRepository.createChat(param);
  }),

  createMessageforCandidate: createAction(
    ModuleEvents.CREATE_MESSAGE_FOR_CHAT,
    async (param, chatId) => {
      return await MessageRepository.creatMessage(param, chatId);
    }
  ),

  getChats: createAction(ModuleEvents.GET_CHAT_LIST, async (param) => {
    return await MessageRepository.getChatList(param);
  }),

  getMessagesForAChat: createAction(
    ModuleEvents.GET_MESSAGES,
    async (param) => {
      return await MessageRepository.getMessagesForChat(param);
    }
  ),

  getProfileViews: createAction(
    ModuleEvents.GET_PROFILE_VIEWS,
    async (param) => {
      return await ProfileViewRepository.getProfileView(param);
    }
  ),

  getALLNotifications: createAction(
    ModuleEvents.GET_ALL_NOTIFICATIONS,
    async () => {
      return await DashboardRepository.getAllNotifications();
    }
  ),

  getUnreadNotifications: createAction(
    ModuleEvents.GET_UNREAD_NOTIFICATIONS,
    async () => {
      return await DashboardRepository.getUnreadNotifications();
    }
  ),

  getReadNotifications: createAction(
    ModuleEvents.GET_READ_NOTIFICATIONS,
    async () => {
      return await DashboardRepository.getReadNotifications();
    }
  ),

  markAllNotificationsAsRead: createAction(
    ModuleEvents.MARK_ALL_NOTIICATIONS,
    async () => {
      return await DashboardRepository.markAllNotificationsAsRead();
    }
  ),

  markNotifocationAsRead: createAction(
    ModuleEvents.MARK_NOTIICATION,
    async (param) => {
      return await DashboardRepository.markNotificationAsRead(param);
    }
  ),

  getInterviewsCandidate: createAction(
    ModuleEvents.GET_INTERVIEWS_CANDIDATE,
    async () => {
      return await InterviewRepository.getInterviewsCandidate();
    }
  ),

  getInterviewDetailsCandidate: createAction(
    ModuleEvents.GET_INTERVIEW_DETAILS_CANDIDATE,
    async (param) => {
      return await InterviewRepository.getInterviewDetailsCandidate(param);
    }
  ),

  getJobOffersCandidate: createAction(
    ModuleEvents.GET_JOB_OFFERS_CANDIDATE,
    async () => {
      return await InterviewRepository.getJobOffersCandidate();
    }
  ),
  getScreeningQuestionsList: createAction(
    ModuleEvents.GET_SCREEING_QUESTIONS_LIST,
    async () => {
      return await ScreeningQuestionsRepository.getAvailableScreeningQuestions();
    }
  ),

  getQuestions: createAction(
    ModuleEvents.GET_SCREEING_QUESTIONS,
    async (param) => {
      return await ScreeningQuestionsRepository.getScreeningQuestions(param);
    }
  ),

  answerQuestions: createAction(
    ModuleEvents.ANSWER_SCREENING_QUESTIONS,
    async (param) => {
      return await ScreeningQuestionsRepository.answerScreeningQuestions(param);
    }
  ),

  getSkills: createAction(ModuleEvents.SKILLS_LIST, async () => {
    return await CommonRepository.skillsList();
  }),

  getJobsList: createAction(ModuleEvents.JOB_ROLE_LIST, async () => {
    return await CommonRepository.jobRoles();
  }),

  getRecruiterDashboard: createAction(
    ModuleEvents.GET_RECRUITER_DASHBOARD,
    async () => {
      return await DashboardRepository.getRecruiterDashboard();
    }
  ),

  createJobpost: createAction(ModuleEvents.CREATE_JOB_POST, async (params) => {
    return await HomeRepository.createJobpost(params);
  }),

  searchCandidates: createAction(
    ModuleEvents.SEACRH_CANDIDIATES,
    async (id, params) => {
      return await HomeRepository.searchCandidates(id, params);
    }
  ),

  createmcqQuestions: createAction(
    ModuleEvents.CREATE_MCQ_QUESTIONS,
    async (params) => {
      return await HomeRepository.createmcqQuestions(params);
    }
  ),

  createvideoQuestions: createAction(
    ModuleEvents.CREATE_VIDEO_QUESTIONS,
    async (params) => {
      return await HomeRepository.createvideoQuestions(params);
    }
  ),

  getScreeningQuestions: createAction(
    ModuleEvents.GET_SCREENING_QUESTIONS,
    async (id, params) => {
      return await HomeRepository.getScreeningQuestions(id, params);
    }
  ),

  updateScreeningQestions: createAction(
    ModuleEvents.UPDATE_QUESTIONS,
    async (params) => {
      return await HomeRepository.updateScreeningQestions(params);
    }
  ),

  deleteScreeningQuestions: createAction(
    ModuleEvents.DELETE_SCREENING_QUESTIONS,
    async (params) => {
      return await HomeRepository.deleteScreeningQuestions(params);
    }
  ),

  getAppliedCandidates: createAction(
    ModuleEvents.GET_APLLIED_CANDIDATES,
    async (params) => {
      return await HomeRepository.getAppliedCandidates(params);
    }
  ),

  getRejectedCandidates: createAction(
    ModuleEvents.GET_REJECTED_CANDIDIATES,
    async (params) => {
      return await HomeRepository.getRejectedCandidates(params);
    }
  ),

  getQualifiedCandidates: createAction(
    ModuleEvents.GET_QUALIFIED_CANDIDIATES,
    async (params) => {
      return await HomeRepository.getQualifiedCandidates(params);
    }
  ),

  getOfferedCandidates:createAction(ModuleEvents.GET_OFFERED_CANDIDATES, async (params) => {
    return await HomeRepository.getOfferedCandidates(params);
  }),

  getOfferRejectedCandidates:createAction(ModuleEvents.GET_OFFERREJECTED_CANDIDIATES, async (params) => {
    return await HomeRepository.getOfferRejectedCandidates(params);
  }),

  getOfferAcceptededCandidates:createAction(ModuleEvents.GET_OFFERACCEPTED_CANDIDIATES, async (params) => {
    return await HomeRepository.getOfferAcceptededCandidates(params);
  }),

  getInterviewList: createAction(
    ModuleEvents.GET_INTERVIEW_LIST,
    async (params) => {
      return await HomeRepository.getInterviewList(params);
    }
  ),
  getInterviewDetails: createAction(
    ModuleEvents.GET_INTERVIEW_DETAILS,
    async (params) => {
      return await HomeRepository.getInterviewDetails(params);
    }
  ),
  addInterview: createAction(ModuleEvents.ADD_INTERVIEW, async (params) => {
    console.log(params,'aparaaammmmm')
    return await HomeRepository.addInterview(params);
  }),

  updateInterview: createAction(
    ModuleEvents.UPDATE_INTERVIEW,
    async (params) => {
      return await HomeRepository.getInterviewDetails(params);
    }
  ),

  markasQualified: createAction(
    ModuleEvents.MARK_AS_QUALIFIED,
    async (params) => {
      return await HomeRepository.markasQualified(params);
    }
  ),

  markasRejected:createAction(ModuleEvents.MARK_AS_REJECTED, async (params) => {
    return await HomeRepository.markasRejected(params);
  }),

  offerJob:createAction(ModuleEvents.OFFER_JOB, async (params) => {
    return await HomeRepository.offerJob(params);
  }),


  initiateScreening:createAction(ModuleEvents.INITIATE_SCREENING, async (id,params) => {
    return await DashboardRepository.initiateScreening(id,params);
  }),

  createConversation:createAction(ModuleEvents.CREATE_CONVERSATION, async (params) => {
    console.log(params,'oaramsssss')
    return await HomeRepository.createConversation(params);
  }),

  getConversationList:createAction(ModuleEvents.GET_CONVERSATION_LIST, async (params) => {
    return await HomeRepository.getConversationList(params);
  }),

  getMessages:createAction(ModuleEvents.GET_MESSAGES_RECRUIT, async (params) => {
    return await HomeRepository.getMessages(params);
  }),
  createMessage:createAction(ModuleEvents.CREATE_MESSAGE, async (params) => {
    return await HomeRepository.createMessage(params);
  }),

  getAnswers:createAction(ModuleEvents.GET_ANSWERS_RECRUITER, async (params) => {
    return await HomeRepository.getAnswers(params);
  }),

  getSingleCandidate :createAction(ModuleEvents.GET_SINGLE_CANDIDITE, async (params) => {
    return await HomeRepository.getSingleCandidate(params);
  }),


  storeCreateJob:createAction(ModuleEvents.STORE_CREATE_JOB,async (params)=>params),

  resetCreateJob:createAction(ModuleEvents.RESET_CREATE_JOB,() => {} )


};
