import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { IMAGES } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../../core/modules/Actions'
import { IMAGE_URL, onToastNotify } from '../../../core/Constant'
import { ButtonComponent, ChipCardlet } from '../../atoms'
import _ from 'lodash'

export const CandidateViewModal = ({ candiaiteId, onClose, isOpen = false, candidiatedata }) => {
    const [open, setOpen] = useState(isOpen)
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()
    const candidiate = useSelector((state) => state.home.get('singleCandidiate'));


    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])


    useEffect(() => {
        if (candiaiteId && open) {
            dispatch(Actions.home.getSingleCandidate(candiaiteId))
        }
        console.log(candidiatedata, 'candididate data')
    }, [candiaiteId, open, dispatch])


    const handleClose = () => {
        setOpen(false)
        onClose()
    }

    const handleOpenCV = () => {
        if (candidiatedata?.application.cv_url) {
            window.open(IMAGE_URL + candidiatedata?.application.cv_url
                , "_blank");
        } else {
            onToastNotify('CV Not Available')
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 flex items-center justify-center"
                initialFocus={cancelButtonRef}
                onClose={handleClose} // Close modal when clicking outside or pressing Esc
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="relative w-full max-w-4xl">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <Dialog.Panel className="w-full max-w-4xl bg-white rounded-lg shadow-xl">
                                <div className="p-8 space-y-6">
                                    <div className="flex flex-col sm:flex-row items-center justify-between">
                                        <div className="flex items-center mb-4 sm:mb-0">
                                            <img className="w-20 h-20 mr-4 rounded-full border-2 border-white bg-cover" src={IMAGE_URL + candidiate.profile_image} alt="Company Logo" />
                                            <div className='flex-col flex gap-1 w-full'>
                                                <span className="text-lableColor font-DM text-lg font-medium">
                                                    {candidiate?.display_name
                                                    }
                                                </span>
                                                <span className="text-lighterGreen font-DM text-md font-medium">
                                                    {candidiatedata?.
                                                        application
                                                        .matching_percentage}% Matching profile
                                                </span>
                                                <span className="text-textlightColor font-DM text-sm font-medium">
                                                    {candidiate?.headline}
                                                </span>

                                            </div>
                                        </div>
                                        <CloseButton onClick={handleClose} />
                                    </div>
                                    <div className='w-2/3 h-auto flex-row flex gap-2 flex-wrap'>
                                        {_.map(candidiate.UserSkills, (skill, index) => {
                                            return (

                                                <ChipCardlet text={skill.
                                                    skill_name} />

                                            );
                                        })}
                                        
                                    </div>


                                    <div className='w-2/5'>
                                        <ButtonComponent
                                            icon={true}
                                            text="Download CV / Resume"
                                            width="w-full"
                                            isPrimary={false}
                                            onClick={handleOpenCV}
                                        />

                                    </div>
                                    <div className="space-y-2 bg-bgColor text-left p-4 rounded-b-lg">
                                        <h2 className="text-lg font-medium text-lableColor">Profile Summery</h2>
                                        <p className="text-sm text-gray-500">
                                            {candidiate?.bio}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {candidiatedata?.application.
                                                message}
                                        </p>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
