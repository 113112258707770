import React, { useEffect, useRef, useState } from "react";
import {
  CandidiateCard,
  CandidiateJobCard,
  CareerAdviceCard,
  HintsCard,
  InterviewCard,
  JobApplyModal,
  JobCard,
  JobPostViewModal,
  JobViewModal,
  NotesCard,
  ProfileDetailCard,
  ProfileViewModal,
  SubHeading,
  UpskillCard,
} from "../../../components/molecule";
import { Header, SidebarContainer } from "../../../components/organism";
import { IMAGES } from "../../../assets/images";
import {
  ButtonComponent,
  DropdownComponent,
  OutlineButtonComponent,
  SkillCardlet,
  TextCardlet,
} from "../../../components/atoms";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { navigateTo } from "../../../core/services/NavigationServicd";
import { onToastNotify } from "../../../core/Constant";
import { CandidateViewModal } from "../../../components/molecule/Modals";

const DashboardRecruiterScreen = ({ getRecruiterDashboard, recruiterDashboard, jobPost, getJobPost, searchCandidiates, initiateScreening,getAllJobRecruiter,recruiterJobs }) => {
  const [dashboardData, setDashboardData] = useState()
  const [candidiates, setCandidiates] = useState()
  const [jobs, setJobs] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [isexpand, setIsexpand] = useState(false)
  const [searchcandidates, setSearchCandidiates] = useState([])
  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState([]);
  const [isExplore,setIsExplore] = useState(false)
  const [selectAll, setSelectAll] = useState(false);
  const [iscandiaiteModal,setIsCandidiateModal] = useState(false)
  const [selectedcandidate,setSelectedCandidite] = useState()
  const [selectedcandidateid,setselectedCandiditeId] = useState()

  const candidateSectionRef = useRef(null);


  const handleOpenModal = (jobId) => {
    setSelectedJobId(jobId)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedJobId(null)
  }

  const handleOpenProfileModal = (candidiate) => {
    console.log(candidiate,'candiaite')
    setSelectedCandidite(candidiate)
    setselectedCandiditeId(candidiate.application.Candidate.user_id)
    setIsCandidiateModal(true)
  }

  const handleProfileCloseModal = () => {
    setIsCandidiateModal(false)
    setSelectedJobId(null)
  }




  useEffect(() => {
    getRecruiterDashboard()
    getAllJobRecruiter()
  }, [])



  useEffect(() => {
    setDashboardData(recruiterDashboard)
    setCandidiates(recruiterDashboard?.candidateList)
    setJobs(recruiterDashboard?.latestJobs)
    console.log(recruiterDashboard, 'recruiterDashboard')
  }, [recruiterDashboard])

  const oncreateJob = () => {
    navigateTo('/recruiter_createjob')
  }

  const handelFindCandidiate = () => {
    if (isexpand) {
      setIsexpand(false)
    }
    else {
      setIsexpand(true)
    }
  }

  const onBackClick = () => {
    if (isexpand) {
      setIsexpand(false)
    }
  }

  useEffect(() => {
    setSearchCandidiates(searchCandidiates || []);
  }, [searchCandidiates]);

  useEffect(() => {
    if (searchcandidates.length > 0 && candidateSectionRef.current) {
      candidateSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchcandidates]);



  const onSelectAll = () => {
    if (selectAll) {
      setSelectedCandidatesIds([]);
    } else {
      const allCandidateIds = searchcandidates.map((candidate) => candidate?.application.id);
      setSelectedCandidatesIds(allCandidateIds);
    }
    setSelectAll(!selectAll);
  };


  const handleSelectCandidate = (candidateId, isSelected) => {
    if (isSelected) {
      setSelectedCandidatesIds((prevSelected) => [...prevSelected, candidateId]);
    } else {
      setSelectedCandidatesIds((prevSelected) =>
        prevSelected.filter((id) => id !== candidateId)
      );
    }
  };

  console.log("Selected Candidate IDs:", selectedCandidatesIds);

  const onExploreClick = () =>{
    console.log('clickeddd')
      if(!isExplore){
        setIsExplore(true)
        setJobs(recruiterJobs)
      }
      else{
        setIsExplore(false)
        setJobs(recruiterDashboard?.latestJobs)
      }
  }

  const onClearClick = () =>{
    setSearchCandidiates([])
  }



  const onInitiateScreening = () => {
    if (selectedCandidatesIds.length > 0) {
      const firstCandidate = searchcandidates.find(candidate => candidate?.application.id === selectedCandidatesIds[0]);
      console.log(firstCandidate, 'first candidite')
      if (firstCandidate && firstCandidate.application) {
        const jobId = firstCandidate.application.job_id;

        initiateScreening(jobId, { appliedIds: selectedCandidatesIds })
          .then(() => {
          
          })
          .catch((error) => {
            onToastNotify('Error initiating screening. Please try again.');
            console.error('Error initiating screening:', error);
          });
      } else {
        onToastNotify('Job ID not found for the selected candidates');
      }
    } else {
      onToastNotify('Please select candidates');
    }
  };




  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        {
          !jobs ? <div className="banner-card-container">
            <span className="text-5xl text-white text-center  font-DM font-[700] uppercase">
              Discover a World of
              <br /> Diverse Talent
            </span>
            <span className="text-base text-center mt-4 text-white font-DM  font-[500]">
              Post your first job today and start building your team. Outsmart
              your competition, attract top <br /> Candidates, and build a winning
              team with our global talent platform.
            </span>
            <div className="items-center my-8">
              <ButtonComponent
                background="bg-white"
                // width=""
                fontColor="text-primaryColor"
                text="Post your first job"
                onClick={oncreateJob}
              />
            </div>
          </div> : <></>
        }
        <div className="suggested-positions-section ">
          <div>
            <SubHeading title={"My Job Listings"}    onClick={()=>onExploreClick()} buttonText={isExplore ? "Close" :"Explore More"} />
          </div>
          <div className="bg-white mt-4 py-4 rounded-xl px-8">
            {/* <div class="flex flex-row gap-2 max-w-[500px] col-span-2 ">
              <DropdownComponent />
              <DropdownComponent />
              <DropdownComponent />
            </div> */}
            {
              jobs ? jobs.map((item, index) => {
                return <InterviewCard isexpand={isexpand} onBackClick={onBackClick} onfindClick={() => handelFindCandidiate()} onViewJob={() => handleOpenModal(item.id)} data={item} isrecruiter={true} key={index + 1} />;
              }) : <></>
            }
            {/* {jobs.map((_, index) => {
              return <InterviewCard isrecruiter={true} key={index + 1} />;
            })} */}
            {Array.isArray(searchcandidates) && searchcandidates.length > 0 ? (
              <>
                <SubHeading
                  title={"Hey Mary, here are the potential candidates for you "}
                  buttonText={"Explore More"}
                  isClear={true}
                  onClearClick={onClearClick}
                  
                />
                <div  ref={candidateSectionRef} className="suggested-positions-section my-8 ">
                  <div className="grid grid-flow-row-dense grid-cols-2 gap-2 items-center">
                    <div className="col-span-2 flex justify-end">
                      <div className="grid grid-flow-row-dense grid-cols-2 gap-2 w-full 2xl:w-fit">
                        <ButtonComponent onClick={() => onInitiateScreening()} padding="p-2" fontsize="text-sm" text={"Initiate Screenings"} isPrimary={true} />
                        <ButtonComponent padding="p-2" fontsize="text-sm" onClick={onSelectAll}   text={selectAll ? "Unselect All" : "Select All"} isPrimary={false} />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-3 gap-2 mt-3">
                  {searchcandidates.map((candidate, index) => (
                <CandidiateCard
                  candidate={candidate}
                  key={index}
                  isRecruiter={true}
                  isSelected={selectedCandidatesIds.includes(candidate?.application.id)} 
                  onSelectCandidate={handleSelectCandidate}
                  onViewProfile={() => handleOpenProfileModal(candidate)}
                />
              ))}
                  </div>
                </div>
              </>
            ) : null}

          </div>
        </div>

        {/* <div className="suggested-positions-section my-8">
          <SubHeading
            title={"Positions that might be right for you"}
            buttonText={"Explore More"}
          />
          <div className="grid grid-flow-row-dense grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 gap-2 mt-2">
            {Array.from({ length: 5 }).map((_, index) => {
              return <CandidiateJobCard key={index + 1} />;
            })}
          </div>
        </div> */}
        {isModalOpen && (
          <JobPostViewModal
            jobId={selectedJobId}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        )}

{iscandiaiteModal && (
          <CandidateViewModal
            candiaiteId={selectedcandidateid}
            isOpen={iscandiaiteModal}
            candidiatedata={selectedcandidate}
            onClose={handleProfileCloseModal}
          />
        )}



      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    recruiterDashboard: state.home.get("recruiterDashboard"),
    jobPost: state.jobs.get("jobPost"),
    searchCandidiates: state.home.get('searchCandidiates'),
    recruiterJobs:state.jobs.get("recruiterJobs"),

  }),
  {
    getRecruiterDashboard: Actions.home.getRecruiterDashboard,
    getJobPost: Actions.jobs.getJobPost,
    initiateScreening: Actions.home.initiateScreening,
    getAllJobRecruiter:Actions.jobs.getAllJobRecruiter,


  },
)(DashboardRecruiterScreen);
