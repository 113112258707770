import React, { useState } from "react";
import {
  ButtonComponent,
  HeadingComponent,
  StepperComponent,
} from "../../atoms";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { AUTH_PROCESS_STEPS } from "../../../core/Constant";

export const CandidateStepTwo = ({
  // selectedJobType = () => {},
  onClickLogin = () => {},
}) => {
  const [jobType, setJobType] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const onClickTypeBtn = (value) => {
    // selectedJobType(value);
    setJobType(value);
    setError(false);
  };

  const onClickBackBtn = () => {
    dispatch(
      Actions.auth.authStep({
        step: AUTH_PROCESS_STEPS.onboarding_pick_location_can,
      })
    );
  };

  const onClickContinue = () => {
    if (jobType !== "") {
      dispatch(Actions.auth.storeUserDetails({ job_type: jobType }));
      dispatch(
        Actions.auth.authStep({
          step: AUTH_PROCESS_STEPS.onboarding_profile_can,
        })
      );
    } else {
      setError(true);
    }
  };
  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <StepperComponent totalSteps={3} currentStep={2} />
      </div>
      <HeadingComponent
        headingText={"What type of jobs you looking for?"}
        description={"In publishing and graphic design, Lorem ipsum"}
      />

      <div className="w-full">
        <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 gap-y-3">
          <button
            onClick={() => {
              onClickTypeBtn("Full-time");
            }}
            className={`liteGray2Btn ${
              jobType == "Full-time" ? "border-gray-700" : null
            }`}
          >
            Full-Time
          </button>
          <button
            onClick={() => {
              onClickTypeBtn("Part-time");
            }}
            className={`liteGray2Btn ${
              jobType == "Part-time" ? "border-gray-700" : null
            }`}
          >
            Part-Time
          </button>
          <button
            onClick={() => {
              onClickTypeBtn("Contract");
            }}
            className={`liteGray2Btn ${
              jobType == "Contract" ? "border-gray-700" : null
            }`}
          >
            Contarct
          </button>
          <button
            onClick={() => {
              onClickTypeBtn("Internship");
            }}
            className={`liteGray2Btn ${
              jobType == "Internship" ? "border-gray-700" : null
            }`}
          >
            Internship
          </button>
        </div>
      </div>

      <div>
        {error ? (
          <p className="font-DM text-errorColor ">
            Please select a job type to continue
          </p>
        ) : null}
      </div>

      <div className="submit-button flex items-center gap-3 w-full 2xl:w-1/3 xl:w-1/3 lg:w-3/5 md:w-3/5">
        <ButtonComponent
          padding="p-2"
          text="Back"
          width="w-full"
          isPrimary={false}
          onClick={onClickBackBtn}
        />
        <ButtonComponent
          padding="p-2"
          text="Continue"
          width="w-full"
          isPrimary={true}
          onClick={() => onClickContinue()}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span
            className="text-primaryColor font-semibold cursor-pointer ps-1"
            onClick={() => onClickLogin()}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
