import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownComponent, ToggleButtonComponent, TextFieldComponent } from "../../atoms"; // Make sure to import TextFieldComponent
import { Actions } from "../../../core/modules/Actions";

const workType = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Full-Time' },
  { id: 3, name: 'Part-Time' },
  { id: 4, name: 'Contract' },
  { id: 5, name: 'Internship' },
  { id: 6, name: 'Apprenticeship' },
  { id: 7, name: 'Seasonal' },
  { id: 8, name: 'Freelance' },
];

export const JobTable = ({ jobData = [], onEditClick }) => {
  const dispatch = useDispatch();
  const [selectedJobType, setSelectedJobType] = useState(""); 
  const [jobTitleFilter, setJobTitleFilter] = useState("");  

  const onsetUpdate = (id, status) => {
    console.log(status, id, "status update");
    if (status) {
      dispatch(Actions.jobs.setRecruiterjobStatus(id, 0));
    } else {
      dispatch(Actions.jobs.setRecruiterjobStatus(id, 1));
    }
  };

  const filteredJobData = useMemo(() => {
    if (!Array.isArray(jobData)) return [];

    let filteredData = jobData;

    if (selectedJobType && selectedJobType !== "All") {
      filteredData = filteredData.filter((job) => job.job_type === selectedJobType);
    }

    if (jobTitleFilter) {
      filteredData = filteredData.filter((job) =>
        job.title.toLowerCase().includes(jobTitleFilter.toLowerCase())
      );
    }

    return filteredData;
  }, [jobData, selectedJobType, jobTitleFilter]);


  return (
    <div>

      <div className="mb-4 grid grid-cols-5 gap-2">
        <DropdownComponent
          label="Filter by Job Type"
          dropdownOptions={workType}
          defaultOption="Select Job Type"
          onOptionSelected={(option) => setSelectedJobType(option.name)} 
        />

        <TextFieldComponent
          label="Filter by Job Title"
          placeholder="Enter job title"
          value={jobTitleFilter}
          onChange={(e) => setJobTitleFilter(e.target.value)}
        />
      </div>
      <div className="overflow-y-scroll w-full h-[500px]">
      <table className="w-full table-auto">
        <thead className="bg-primaryColor">
          <tr>
            <th className="p-4 text-white font-DM text-md font-medium text-center">
              Job Title
            </th>
            <th className="text-white font-DM text-md font-medium text-center">
              Date Posted
            </th>
            <th className="text-white font-DM text-md font-medium text-center">
              Status
            </th>
            <th></th>
            <th></th>
            <th className="p-4 text-white font-DM text-md font-medium text-center"></th>
          </tr>
        </thead>
        <tbody className="w-full">
          {filteredJobData?.map((job, index) => (
            <tr
              key={job.id}
              className={index % 2 === 0 ? "bg-white" : "bg-bgColor"}
            >
              <td className="pt-2 pb-2 text-textGrey font-DM text-md font-medium text-center">
                {job.title}
              </td>
              <td className="pt-2 pb-2 text-textGrey font-DM text-md font-medium text-center">
                {new Date(job.createdAt).toLocaleDateString()}
              </td>
              <td className="pt-2 pb-2 pl-4 pr-4 text-center">
                <div className={`p-2 rounded-full ${job.active_status ? "bg-lighterGreen text-white" : "bg-blue-500 text-white"
                  }`}>
                  <span
                    className={`font-DM text-sm font-medium`}
                  >
                    {job.active_status ? "Active" : "Inactive"}
                  </span>
                </div>

              </td>
              <td className="pt-2 pb-2 pl-4 pr-4 text-center">
                <button
                  className="flex flex-row"
                  onClick={() => onEditClick(job.id)}
                >
                  <Icon />
                  <span className="text-textGrey font-DM text-md font-medium ml-2">
                    Edit
                  </span>
                </button>
              </td>
              <td >
                <ToggleButtonComponent
                  checked={job.active_status} // Pass active_status to the ToggleButtonComponent
                  onClick={() => {
                    onsetUpdate(job.id, job.active_status);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

// Icon component for the Edit button
const Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.68151 2.3877H3.29988C2.81631 2.3877 2.35254 2.58351 2.0106 2.93206C1.66866 3.28061 1.47656 3.75335 1.47656 4.24628V17.2564C1.47656 17.7493 1.66866 18.222 2.0106 18.5706C2.35254 18.9192 2.81631 19.115 3.29988 19.115H16.0631C16.5467 19.115 17.0105 18.9192 17.3524 18.5706C17.6944 18.222 17.8865 17.7493 17.8865 17.2564V10.7513"
        stroke="#627A90"
        strokeWidth="1.67273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4936 2.0393C15.8563 1.66961 16.3482 1.46191 16.8611 1.46191C17.374 1.46191 17.8659 1.66961 18.2286 2.0393C18.5913 2.409 18.7951 2.91041 18.7951 3.43324C18.7951 3.95607 18.5913 4.45749 18.2286 4.82718L9.6818 13.5393L6.03516 14.4686L6.94682 10.7514L15.4936 2.0393Z"
        stroke="#627A90"
        strokeWidth="1.67273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
