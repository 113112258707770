import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets";
import { AUTH_PROCESS_STEPS } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import {
  ButtonComponent,
  HeadingComponent,
  StepperComponent,
} from "../../atoms";
import { PdfUploadCard } from "../../molecule/Cards";
import { UploadDetailsModel } from "../../molecule/Modals";
import {
  getLinkdinUser,
  getOnboardingDetails,
  setOnboardingDetails,
  setOnboardingInfor,
  setOnboardingStep,
} from "../../../core/UtilMethods";

export const CandidateStepThree = ({ onClickLogin = () => {} }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [error, setError] = useState(false);

  const userDetails = useSelector((state) => state.auth.get("userDetails"));

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    console.log(userDetails)
    if(getLinkdinUser() == null){
      setOnboardingDetails(JSON.stringify(userDetails));
      setOnboardingStep(AUTH_PROCESS_STEPS.onboarding_profile_can);
    }
  }, [userDetails]);

  const handleOnPressRemove = () => {
    setFile(null);
    setIsSelected(false);
  };
  const onClickBack = () => {
    dispatch(
      Actions.auth.authStep({
        step: AUTH_PROCESS_STEPS.onboarding_pick_jobType_can,
      })
    );
  };

  const getFileSelected = (selectedFile, isFileSelected) => {
    setFile(selectedFile);
    setIsSelected(isFileSelected);
    setError(false);
  };

  const handleLinkedImport = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/linkedin";
  };
  const handleOnClick = () => {
    const details = JSON.parse(getOnboardingDetails());
    if (file !== null && isSelected) {
      const blob = new Blob([file], { type: file.type });
      const data = new FormData();
      data.append("email", _.get(details, "email"));
      data.append("password", _.get(details, "password"));
      data.append("confirm_password", _.get(details, "confirmPassword"));
      data.append("preferred_job_types", _.get(details, "job_type"));
      data.append("linkedin", getLinkdinUser());
      data.append("microsoft_id", "");
      data.append("facebook_id", "");
      data.append("google_id", "");
      data.append("cv_url", blob);

      const locations = _.get(details, "locations", []);
      locations.forEach((item) => {
        const locationId = _.get(item, "id");
        if (locationId) {
          data.append("preferred_work_locations", locationId);
        }
      });

      dispatch(
        Actions.auth.registerCandidate(
          data,
          { "Content-Type": "multipart/form-data" },
          (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log("percentage: ", percent);
          }
        )
      );
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className="sign-in-form-container">
        <div className="back-button-container">
          <StepperComponent totalSteps={3} currentStep={3} />
        </div>
        <HeadingComponent
          headingText={"Setup your profile"}
          description={"In publishing and graphic design, Lorem ipsum"}
        />

        <div className="w-full 2xl:w-2/3 xl:w-3/6 lg:w-2/3 md:w-2/3 ">
          <div className="bg-[#E4F1FC] rounded-xl grid grid-cols-6 p-2 w-full gap-1 items-center">
            <div className="col-span-1">
              <img src={IMAGES.ONBORADING.FIRE_ICON} alt="logo" />
            </div>
            <div className="col-span-5">
              <p className="font-DM text-sm">
                We will generate and fill your information straight from your CV
                or linkedin
              </p>
            </div>
          </div>
        </div>

        <div className="w-full 2xl:w-2/3 xl:w-2/3  lg:w-4/5 md:w-2/3">
          <div className="import-button-container grid grid-flow-col-dense grid-cols-3 gap-2">
            <div className="col-span-3 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2">
              <button
                className="bg-[#417DCD] w-full h-full flex justify-center items-center p-2 rounded-md gap-2"
                onClick={handleLinkedImport}
              >
                <p className="font-DM text-white text-lg font-semibold ">
                  Import from LinkedIn
                </p>
                <img src={IMAGES.ONBORADING.LINKEDIN_ICON} alt="logo" />
              </button>
            </div>
            <div className="col-span-3 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1">
              <PdfUploadCard
                buttonLabel="Upload cv"
                setFileNFileSelected={getFileSelected}
                file={file}
                isFileSelected={isSelected}
                removeSelectedFile={handleOnPressRemove}
              />
            </div>
          </div>
        </div>
        {!isSelected && error ? (
          <div>
            <span className="font-DM text-errorColor">
              Please upload your CV before continue!
            </span>
          </div>
        ) : null}

        <div className="submit-button flex items-center gap-3 w-full 2xl:w-1/3 xl:w-1/3 lg:w-3/5 md:w-3/5">
          <ButtonComponent
            padding="p-2"
            text="Back"
            width="w-full"
            isPrimary={false}
            onClick={() => onClickBack()}
          />
          <ButtonComponent
            padding="p-2"
            text="Continue"
            width="w-full"
            isPrimary={true}
            onClick={() => handleOnClick()}
          />
        </div>
        <div>
          <p className="font-DM font-medium text-lableColor">
            Already have an account?
            <span
              className="text-primaryColor font-semibold cursor-pointer ps-1"
              onClick={() => onClickLogin()}
            >
              Login
            </span>
          </p>
        </div>
      </div>
      <UploadDetailsModel open={open} onClose={() => setOpen(false)} />
      {/* <SuccessMessageModel
        open={isSuccess}
        onClose={() => setIsSuccess()}
        setCandidateStep={setCandidateStep}
      /> */}
    </>
  );
};
