import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { IMAGES } from '../../../assets'
import { TabComponent } from '../Tabs/TabComponent'
import { EditJobFormOne, EditJobFormTwo } from '../../organism'

export const JobPostEditModal = ({ text = "label", onClose, close = false ,jobId }) => {
    const [open, setOpen] = useState(true)
    const cancelButtonRef = useRef(null)
    const [activeTab, setActiveTab] = useState('Job Details'); 

    const tabList = ['Job Details', 'Job Description'];

    const tabContent = {
        'Job Details': <EditJobFormOne jobId={jobId} setActiveTab={setActiveTab} />,  
        'Job Description': <EditJobFormTwo jobId={jobId} onClose={onClose} />,
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 flex items-center justify-center"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="relative w-full max-w-4xl">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <Dialog.Panel className="w-full max-w-4xl bg-white rounded-lg shadow-xl">
                                <div className="p-8 space-y-6">
                                    <div class="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                                        <div class="flex flex-row w-full">
                                            <span class=" text-lableColor font-DM font-[500] ml-5 text-[28px]">Edit the job post</span>
                                        </div>
                                        <CloseButton onClick={onClose}/>
                                    </div>
                                    <TabComponent 
                        tabList={tabList} 
                        tabContent={tabContent} 
                        activeTab={activeTab}  // Pass the activeTab from state
                        setActiveTab={setActiveTab}  // Pass the setActiveTab function
                    />
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
